import React from 'react';

export const mypageConfig = [
  {
    auth: ['Employee', 'Staff'],
    routes: [
      {
        path: '/mypage/edit',
        component: React.lazy(() => import('./MypageEdit')),
      },
    ],
  },
];
