import React from 'react';
import {Redirect} from 'react-router-dom';

import {createRoutes} from '../@kirei/utility/Utils';
import {samplePagesConfig} from './sample';
import {errorPagesConfigs} from './errorPages';
import {authRouteConfig} from './auth';
import {instructionsConfig} from './businessInstruction';
import {initialUrl} from '../shared/constants/AppConst';
import {reportConfig} from './report';
import {customersConfig} from './customerManagement';
import {employeeConfig} from './employee';
import {shopConfig} from './shop';
import {mypageConfig} from './myPage';
import {roomConfig} from './ChatRoom';
import {libraryConfig} from './library';
import {homeConfig} from './home';
import {analysisConfig} from './analysis';
import {smaregiImportConfig} from './smaregiImport';

const routeConfigs = [
  ...samplePagesConfig,
  ...errorPagesConfigs,
  ...authRouteConfig,
  ...instructionsConfig,
  ...reportConfig,
  ...customersConfig,
  ...employeeConfig,
  ...shopConfig,
  ...mypageConfig,
  ...roomConfig,
  ...libraryConfig,
  ...homeConfig,
  ...analysisConfig,
  ...smaregiImportConfig,
];

const routes = [
  ...createRoutes(routeConfigs),
  {
    path: '/',
    exact: true,
    component: () => <Redirect to={initialUrl} />,
  },
  {
    component: () => <Redirect to='/error-pages/error-404' />,
  },
];

export default routes;
