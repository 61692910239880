import fetch from 'cross-fetch';
import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloLink,
} from '@apollo/client';
import {setContext} from '@apollo/client/link/context';
import {onError} from '@apollo/client/link/error';
import { relayStylePagination } from '@apollo/client/utilities';

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_GRAPHQL_URI,
  fetch,
});

const authLink = setContext((_, {headers}) => {
  const token = localStorage.getItem('token');

  return {
    headers: {
      ...headers,
      token: token,
    },
  };
});

const errorLink = onError(({graphQLErrors, networkError}) => {
  if (graphQLErrors) {
    // TODO: error messageとか
    // graphQLErrors.map(({ message, locations, path, extensions }) => {
    //     alert(
    //       `以下のエラーメッセージと再現方法を報告してください。\n message:${message}, location: ${locations}, path: ${path}, extensions: ${extensions}`
    //     );
    //   });
    // }
    // if (networkError) {
    // alert(
    //   `以下のエラーメッセージと再現方法を報告してください。\n ${networkError}`
    // );
  }
});

// export const client = new ApolloClient({
//   link: ApolloLink.from([errorLink, authLink.concat(httpLink)]),
//   cache: new InMemoryCache(),
// });

export const client = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink.concat(httpLink)]),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          roomChats: relayStylePagination(),
        },
      },
    },
  }),
});
