export interface NavItemProps {
  id: string;
  messageId: string;
  title: string;
  icon?: string;
  exact?: boolean;
  url?: string;
  type?: string;
  count?: number;
  color?: string;
  auth?: string[];
  children?: NavItemProps[] | NavItemProps;
}

const routesConfig: NavItemProps[] = [
  {
    id: 'sample__home',
    title: 'SampleHome',
    messageId: 'sidebar.sample.home',
    type: 'item',
    icon: 'home',
    url: '/home',
    auth: ['Employee', 'Staff'],
  },
  {
    id: 'sample',
    title: 'Sample',
    messageId: 'sidebar.sample.shop_management',
    type: 'item',
    icon: 'shopping_cart',
    url: '/shopManagement/instructions/index',
    auth: ['Employee', 'Staff'],
  },
  {
    id: 'sample__customer_management',
    title: 'SampleCustomerManagement',
    messageId: 'sidebar.sample.customer_management',
    type: 'item',
    icon: 'person',
    url: '/customers/index',
    auth: ['Employee', 'Staff'],
  },
  {
    id: 'pos_analysis',
    title: 'PosAnalysis',
    messageId: 'sidebar.sample.pos_analysis',
    type: 'item',
    icon: 'insert_chart',
    url: '/analysis/index',
    auth: ['Employee'],
  },
  {
    id: 'library',
    title: 'Library',
    messageId: 'sidebar.library',
    type: 'item',
    icon: 'folder_open',
    url: '/library/index',
    auth: ['Employee', 'Staff'],
  },
  {
    id: 'sample__setting',
    title: 'SampleSetting',
    messageId: 'sidebar.sample.setting',
    type: 'item',
    icon: 'settings',
    url: '/settings/employees/index',
    auth: ['Employee'],
  },
];

export default routesConfig;
