import {
  ChatActions,
  TOGGLE_CHAT_DRAWER,
  GET_USER_MESSAGES,
  ADD_NEW_MESSAGE,
  SELECT_USER,
  GET_CHAT_LIST,
} from 'types/actions/Chat.actions';
import {ChatMessages, ChatUser} from '../../types/models/apps/Chat';

const initialState: {
  chatList: ChatUser[];
  chatDrawer: boolean;
  userMessages: ChatMessages | null;
  selectedUser: ChatUser | null;
} = {
  chatList: [],
  chatDrawer: false,
  userMessages: null,
  selectedUser: null,
};

const ChatApp = (state = initialState, action: ChatActions) => {
  switch (action.type) {
    case GET_CHAT_LIST:
      return {
        ...state,
        chatList: action.payload,
      };

    case TOGGLE_CHAT_DRAWER:
      return {
        ...state,
        chatDrawer: !state.chatDrawer,
      };

    case GET_USER_MESSAGES:
      return {
        ...state,
        userMessages: action.payload,
      };

    case ADD_NEW_MESSAGE: {
      console.log(action.payload);
      if (state.userMessages) {
        state.userMessages.push(action.payload.userMessage);
        return {
          ...state,
          userMessages: state.userMessages,
        };
      } else {
        return {
          ...state,
          userMessages: [action.payload.userMessage],
        };
      }
    }

    case SELECT_USER: {
      return {
        ...state,
        selectedUser: action.payload,
        userMessages: null,
      };
    }

    default:
      return state;
  }
};
export default ChatApp;
