import React from 'react';
import List from '@material-ui/core/List';

import routesConfig, {NavItemProps} from '../../../../modules/routesConfig';
import VerticalCollapse from './VerticalCollapse';
import VerticalItem from './VerticalItem';
import VerticalNavGroup from './VerticalNavGroup';
import {makeStyles} from '@material-ui/core';
import {CremaTheme} from '../../../../types/AppContextPropsType';
import {useAuthUser} from '@kirei/utility/AppHooks';

interface NavigationProps {}

const useStyles = makeStyles((theme: CremaTheme) => {
  return {
    list: {
      paddingTop: 0,
    },
  };
});

const Navigation: React.FC<NavigationProps> = () => {
  const classes = useStyles();
  const user = useAuthUser();

  return (
    <List className={classes.list}>
      {routesConfig.map((item: NavItemProps) => (
        <React.Fragment key={item.id}>
          {item.type === 'group' && <VerticalNavGroup item={item} level={0} />}

          {item.type === 'collapse' && (
            <VerticalCollapse item={item} level={0} />
          )}
          {item.type === 'item' &&
            user &&
            user.role[0] &&
            item.auth &&
            item.auth.includes(user.role[0]) && (
              <VerticalItem item={item} level={0} />
            )}
        </React.Fragment>
      ))}
    </List>
  );
};

export default Navigation;
