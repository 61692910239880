import {Dispatch} from 'redux';
import {AppActions} from '../../types';
import {fetchSuccess} from './Common';
import {
  ChatUser,
  ChatMessage,
  ChatMessages,
} from '../../types/models/apps/Chat';
import {
  GET_USER_MESSAGES,
  ADD_NEW_MESSAGE,
  SELECT_USER,
  GET_CHAT_LIST,
} from 'types/actions/Chat.actions';
import {ChatUsersQuery, RoomChatsQuery} from 'graphql/types';
import {dateTimeFormat} from 'shared/utils/dateHelper';
import {MessageType} from '@kirei/services/db/apps/chat/connectionList';

export const getChatList = (
  chatUsers: ChatUsersQuery['chatUsers']['edges'],
) => {
  if (chatUsers) {
    const list: (ChatUser | undefined)[] = chatUsers.map((edge) => {
      if (edge.node) {
        return {
          id: edge.node.id,
          name: edge.node.name,
          unreadCount: edge.node.unreadCount,
        };
      }
      return undefined;
    });
    const chatList: ChatUser[] = list.filter(
      (v: ChatUser | undefined): v is ChatUser => v != null,
    );
    return (dispatch: Dispatch<AppActions>) => {
      dispatch({type: GET_CHAT_LIST, payload: chatList});
    };
  }
};

type chats = Exclude<RoomChatsQuery['roomChats']['edges'], undefined>;

export const getChatMessages = (chats: chats) => {
  return (dispatch: Dispatch<AppActions>) => {
    const lists: (ChatMessage | undefined)[] = chats.map((chat) => {
      if (chat.node) {
        return {
          id: chat.node.id,
          sender: chat.node.senderId,
          message: chat.node.content,
          message_type: chat.node.fileUrl ? MessageType.DOC : MessageType.TEXT,
          fileName: chat.node.fileName,
          fileUrl: chat.node.fileUrl,
          time: dateTimeFormat(chat.node.createdAt),
        };
      }
      return undefined;
    });

    const chatMessages: ChatMessages = lists.filter(
      (v: ChatMessage | undefined): v is ChatMessage => v != null,
    );

    dispatch({type: GET_USER_MESSAGES, payload: chatMessages.reverse()});
  };
};

export const onSendMessage = (chatData: ChatMessage) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchSuccess());
    dispatch({
      type: ADD_NEW_MESSAGE,
      payload: {
        userMessage: chatData,
      },
    });
  };
};

export const onSelectUser = (user: ChatUser) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({type: GET_USER_MESSAGES, payload: undefined});
    dispatch({type: SELECT_USER, payload: user});
  };
};
