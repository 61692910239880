import React from 'react';

export const customersConfig = [
  {
    auth: ['Employee', 'Staff'],
    routes: [
      {
        path: '/customers/index',
        component: React.lazy(() => import('./CustomerIndex')),
      },
    ],
  },
  {
    auth: ['Employee', 'Staff'],
    routes: [
      {
        path: '/customers/detail/:id?',
        component: React.lazy(() => import('./CustomerDetail')),
      },
    ],
  },
  {
    auth: ['Employee', 'Staff'],
    routes: [
      {
        path: '/customers/transactions/detail/:id?',
        component: React.lazy(() => import('./TransactionDetail')),
      },
    ],
  },
  {
    auth: ['Employee', 'Staff'],
    routes: [
      {
        path: '/customers/edit',
        component: React.lazy(() => import('./UpdateCustomer')),
      },
    ],
  },
];
