import {makeStyles} from '@material-ui/core/styles';
import {Fonts, ThemeMode} from '../../../../shared/constants/AppEnums';
import {CremaTheme} from '../../../../types/AppContextPropsType';

const useStyles = makeStyles((theme: CremaTheme) => {
  return {
    navItem: {
      height: 80,
      cursor: 'pointer',
      textDecoration: 'none !important',
      width: '100%',
      fontSize: '16px !important',
      paddingLeft:
        theme.direction === 'ltr'
          ? (props: {level: number; themeMode: ThemeMode}) =>
              24 + 40 * props.level
          : 12,
      paddingRight:
        theme.direction === 'rtl'
          ? (props: {level: number; themeMode: ThemeMode}) =>
              24 + 40 * props.level
          : 12,
      '&.nav-item-header': {
        textTransform: 'uppercase',
      },
      '&.active': {
        backgroundColor: theme.palette.secondary.main,
        borderLeft: '8px solid ' + theme.palette.primary.main,
        pointerEvents: 'none',
        transition: 'border-radius .15s cubic-bezier(0.4,0.0,0.2,1)',
        '& .nav-item-text': {
          color: theme.palette.primary.main + '!important',
          fontWeight: Fonts.BOLD,
        },
        '& .nav-item-icon': {
          color: theme.palette.primary.main + '!important',
        },
      },

      '&:hover, &:focus': {
        '& .nav-item-text': {
          color: (props: {level: number; themeMode: ThemeMode}) =>
            props.themeMode === ThemeMode.LIGHT
              ? theme.palette.primary.main
              : theme.palette.primary.main,
        },

        '& .nav-item-icon': {
          color: (props: {level: number; themeMode: ThemeMode}) =>
            props.themeMode === ThemeMode.LIGHT
              ? theme.palette.primary.main
              : theme.palette.primary.main,
        },

        '& .nav-item-icon-arrow': {
          color: (props: {level: number; themeMode: ThemeMode}) =>
            props.themeMode === ThemeMode.LIGHT
              ? theme.palette.primary.main
              : '#fff',
        },
      },
      '& .nav-item-icon': {
        color: theme.palette.sidebar.textColor,
      },
      '& .nav-item-text': {
        color: theme.palette.sidebar.textColor,
        fontWeight: Fonts.BOLD,
        fontSize: '16px !important',
        letterSpacing: 4,
      },
    },
    listIcon: {
      fontSize: 18,
      [theme.breakpoints.up('xl')]: {
        // fontSize: 20,
      },
    },
    listItemText: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      fontWeight: Fonts.BOLD,
      fontSize: 16,
    },
  };
});
export default useStyles;
