import React from 'react';

export const reportConfig = [
  {
    auth: ['Employee', 'Staff'],
    routes: [
      {
        path: '/shopManagement/report/index',
        component: React.lazy(() => import('./ReportLists')),
      },
    ],
  },
  {
    auth: ['Employee', 'Staff'],
    routes: [
      {
        path: '/shopManagement/report/detail/:id?',
        component: React.lazy(() => import('./ReportDetail')),
      },
    ],
  },
  {
    auth: ['Employee', 'Staff'],
    routes: [
      {
        path: '/shopManagement/report/new',
        component: React.lazy(() => import('./CreateReport')),
      },
    ],
  },
  {
    auth: ['Employee', 'Staff'],
    routes: [
      {
        path: '/shopManagement/report/edit',
        component: React.lazy(() => import('./UpdateReport')),
      },
    ],
  },
];
