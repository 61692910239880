import React from 'react';

export const shopConfig = [
  {
    auth: ['Employee'],
    routes: [
      {
        path: '/shopManagement/shop/index',
        component: React.lazy(() => import('./ShopLists')),
      },
      {
        path: '/shopManagement/shop/detail/:id?',
        component: React.lazy(() => import('./ShopDetail')),
      },
      {
        path: '/shopManagement/shop/new',
        component: React.lazy(() => import('./CreateShop')),
      },
    ],
  },
  {
    auth: ['Employee'],
    routes: [
      {
        path: '/shopManagement/shop/instruction',
        component: React.lazy(() => import('./ShopListsByInstructionStatus')),
      },
      {
        path: '/shopManagement/shop/edit',
        component: React.lazy(() => import('./UpdateShop')),
      },
    ],
  },
];
