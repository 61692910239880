import jpMessages from '../locales/ja_JP.json';
import {jaJP} from '@material-ui/core/locale';

const jaLang = {
  messages: {
    ...jpMessages,
  },
  muiLocale: jaJP,
  locale: 'ja-JP',
};
export default jaLang;
