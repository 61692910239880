import React from 'react';

export const roomConfig = [
  {
    auth: ['Employee', 'Staff'],
    routes: [
      {
        path: '/room/index',
        component: React.lazy(() => import('./Chat')),
      },
    ],
  },
];
