import React from 'react';

export const instructionsConfig = [
  {
    auth: ['Employee', 'Staff'],
    routes: [
      {
        path: '/shopManagement/instructions/index',
        component: React.lazy(() => import('./InstructionIndex')),
      },
    ],
  },
  {
    auth: ['Employee', 'Staff'],
    routes: [
      {
        path: '/shopManagement/instructions/detail/:id?',
        component: React.lazy(() => import('./InstructionDetail')),
      },
    ],
  },
  {
    auth: ['Employee'],
    routes: [
      {
        path: '/shopManagement/instructions/new',
        component: React.lazy(() => import('./CreateInstruction')),
      },
    ],
  },
  {
    auth: ['Employee'],
    routes: [
      {
        path: '/shopManagement/instructions/edit',
        component: React.lazy(() => import('./UpdateInstruction')),
      },
    ],
  },
];
