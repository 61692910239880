import jwtAxios from '../../@kirei/services/auth/jwt-auth/jwt-api';
import {fetchError, fetchStart, fetchSuccess} from './Common';
import {AuthType} from '../../shared/constants/AppEnums';
import {AuthUser} from '../../types/models/AuthUser';
import {AppActions} from '../../types';
import {Dispatch} from 'redux';
import {
  SET_AUTH_TOKEN,
  SIGNOUT_AUTH_SUCCESS,
  UPDATE_AUTH_USER,
} from '../../types/actions/Auth.actions';
import {LoginUserType} from 'types/models/user/user';

export const onJwtUserSignUp = (body: {
  email: string;
  password: string;
  name: string;
}) => {
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const res = await jwtAxios.post('users', body);
      localStorage.setItem('token', res.data.token);
      dispatch(setJWTToken(res.data.token));
      // TODO: signupはないのでコメントアウト
      // await loadJWTUser(dispatch, body);
    } catch (err) {
      console.log('error!!!!', err.response.data.error);
      dispatch(fetchError(err.response.data.error));
    }
  };
};

export const onJwtSignIn = (user: LoginUserType, token: string) => {
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      localStorage.setItem('token', token);
      dispatch(setJWTToken(token));
      await loadJWTUser(dispatch, user);
    } catch (err) {
      console.log('error!!!!', err.response.data.error);
      dispatch(fetchError(err.response.data.error));
    }
  };
};

export const loadJWTUser = async (
  dispatch: Dispatch<AppActions>,
  user: LoginUserType,
) => {
  dispatch(fetchStart());
  try {
    dispatch(fetchSuccess());
    dispatch({
      type: UPDATE_AUTH_USER,
      payload: getUserObject(user),
    });
  } catch (err) {
    console.log('error!!!!', err.response.error);
    dispatch(fetchError(err.response.error));
  }
};

export const setJWTToken = (token: string | null): AppActions => ({
  type: SET_AUTH_TOKEN,
  payload: token,
});

export const getUserObject = (authUser: LoginUserType): AuthUser => {
  const senderId =
    authUser.__typename === 'Staff' ? authUser.shop.id : authUser.id;
  return {
    uid: authUser.id,
    authType: AuthType.JWT_AUTH,
    email: authUser.email,
    role: [authUser.__typename],
    token: authUser.id,
    unreadChatExist: authUser.unreadChatExist,
    user: authUser,
    chatSenderId: senderId,
    displayName: authUser.name,
  };
};

export const onJWTAuthSignout = () => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchSuccess());
    setTimeout(() => {
      dispatch({type: SIGNOUT_AUTH_SUCCESS, payload: null});
      dispatch(fetchSuccess());
      localStorage.removeItem('token');
    }, 500);
  };
};
