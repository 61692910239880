import React, {FC, useContext} from 'react';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {Link} from 'react-router-dom';
import {makeStyles} from '@material-ui/core';
import {Fonts} from '../../constants/AppEnums';
import AppContext from '../../../@kirei/utility/AppContext';
import AppContextPropsType, {
  CremaTheme,
} from '../../../types/AppContextPropsType';
import {useHistory} from 'react-router';
import {useAuthUser} from '@kirei/utility/AppHooks';

type LinkWithLabel = {
  link: string;
  label: string;
  auth?: string[];
};

type Props = {
  links: LinkWithLabel[];
};

const useStyles = makeStyles((theme: CremaTheme) => ({
  paper: {
    boxShadow: 'none',
    borderBottom: '2px solid #E9E9E9',
  },
  tabs: {
    background: 'white',
  },
  tab: {
    height: 80,
    fontWeight: Fonts.BOLD,
    fontSize: 16,
    color: '#C9C9C9',
    letterSpacing: 4,
  },
}));

const LinkTabs: FC<Props> = ({links}) => {
  const {themeMode} = useContext<AppContextPropsType>(AppContext);
  const classes = useStyles({themeMode});
  const history = useHistory();
  const currentPath = history.location.pathname;
  const tabBasePaths = links.map(({link}) => link.slice(0, -5));
  const index = tabBasePaths.findIndex(
    (path) => currentPath.indexOf(path) !== -1,
  );
  const user = useAuthUser();

  const tabs = links.map((link) => {
    return (
      user &&
      user.role[0] &&
      link.auth &&
      link.auth.includes(user.role[0]) && (
        <Tab
          key={link.link}
          label={link.label}
          component={Link}
          to={link.link}
          className={classes.tab}
        />
      )
    );
  });

  return (
    <Paper square className={classes.paper}>
      <Tabs
        value={index !== -1 ? index : 0}
        indicatorColor='primary'
        textColor='primary'
        TabIndicatorProps={{style: {height: '4px'}}}
        className={classes.tabs}>
        {tabs}
      </Tabs>
    </Paper>
  );
};

export default LinkTabs;
