import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: any;
  /** Represents untyped JSON */
  JSON: any;
};

/** Active Record Interface */
export type ActiveRecordInterface = {
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  updatedAt: Scalars['ISO8601DateTime'];
};

export type AwsUpload = {
  __typename?: 'AwsUpload';
  fields: Scalars['String'];
  url: Scalars['String'];
};

export type Customer_Input = {
  id?: Maybe<Scalars['ID']>;
  lastName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastKana?: Maybe<Scalars['String']>;
  firstKana?: Maybe<Scalars['String']>;
  birthDate?: Maybe<Scalars['ISO8601DateTime']>;
  gender?: Maybe<GenderEnum>;
  address?: Maybe<Scalars['String']>;
  tel?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  visitReason?: Maybe<VisitReasonEnum>;
  reasonDetail?: Maybe<Scalars['String']>;
  medicineReaction?: Maybe<Scalars['Boolean']>;
  patchTest?: Maybe<Scalars['Boolean']>;
  note?: Maybe<Scalars['String']>;
};

export type Chat = ActiveRecordInterface & {
  __typename?: 'Chat';
  content?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  fileName?: Maybe<Scalars['String']>;
  fileUrl?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  roomId: Scalars['ID'];
  senderId: Scalars['ID'];
  updatedAt: Scalars['ISO8601DateTime'];
};

/** The connection type for Chat. */
export type ChatConnection = {
  __typename?: 'ChatConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ChatEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Chat>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ChatEdge = {
  __typename?: 'ChatEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Chat>;
};

export type ChatUser = ActiveRecordInterface & {
  __typename?: 'ChatUser';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  unreadCount: Scalars['Int'];
  updatedAt: Scalars['ISO8601DateTime'];
};

/** The connection type for ChatUser. */
export type ChatUserConnection = {
  __typename?: 'ChatUserConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ChatUserEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<ChatUser>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ChatUserEdge = {
  __typename?: 'ChatUserEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<ChatUser>;
};

/** Autogenerated input type of CreateAnalysis */
export type CreateAnalysisInput = {
  json: Scalars['JSON'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreateAnalysis */
export type CreateAnalysisPayload = {
  __typename?: 'CreateAnalysisPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  result: Scalars['Boolean'];
};

/** Autogenerated input type of CreateChat */
export type CreateChatInput = {
  receiverId: Scalars['ID'];
  content?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  fileUrl?: Maybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreateChat */
export type CreateChatPayload = {
  __typename?: 'CreateChatPayload';
  chat: Chat;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of CreateCustomer */
export type CreateCustomerInput = {
  json: Scalars['JSON'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreateCustomer */
export type CreateCustomerPayload = {
  __typename?: 'CreateCustomerPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  result: Scalars['Boolean'];
};

/** Autogenerated input type of CreateEmployee */
export type CreateEmployeeInput = {
  employeeInput: Employee_Input;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreateEmployee */
export type CreateEmployeePayload = {
  __typename?: 'CreateEmployeePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  employee: Employee;
};

/** Autogenerated input type of CreateInstruction */
export type CreateInstructionInput = {
  instructionInput: Instruction_Input;
  shopIds?: Maybe<Array<Scalars['ID']>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreateInstruction */
export type CreateInstructionPayload = {
  __typename?: 'CreateInstructionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  result: Scalars['Boolean'];
};

/** Autogenerated input type of CreateLibrary */
export type CreateLibraryInput = {
  name: Scalars['String'];
  fileUrl: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreateLibrary */
export type CreateLibraryPayload = {
  __typename?: 'CreateLibraryPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  library: Library;
};

/** Autogenerated input type of CreateProduct */
export type CreateProductInput = {
  json: Scalars['JSON'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreateProduct */
export type CreateProductPayload = {
  __typename?: 'CreateProductPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  result: Scalars['Boolean'];
};

/** Autogenerated input type of CreateReceipt */
export type CreateReceiptInput = {
  json: Scalars['JSON'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreateReceipt */
export type CreateReceiptPayload = {
  __typename?: 'CreateReceiptPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Scalars['String']>>;
  result: Scalars['Boolean'];
};

/** Autogenerated input type of CreateReport */
export type CreateReportInput = {
  reportInput: Report_Input;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreateReport */
export type CreateReportPayload = {
  __typename?: 'CreateReportPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  report: Report;
};

/** Autogenerated input type of CreateShop */
export type CreateShopInput = {
  shopInput: Shop_Input;
  staffPassword: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreateShop */
export type CreateShopPayload = {
  __typename?: 'CreateShopPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  shop: Shop;
};

/** Autogenerated input type of CreateVisit */
export type CreateVisitInput = {
  visitInput: Visit_Input;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreateVisit */
export type CreateVisitPayload = {
  __typename?: 'CreateVisitPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  visit: Visit;
};

export type Customer = ActiveRecordInterface & {
  __typename?: 'Customer';
  address?: Maybe<Scalars['String']>;
  birthDate?: Maybe<Scalars['ISO8601DateTime']>;
  createdAt: Scalars['ISO8601DateTime'];
  firstKana?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  firstVisitDate?: Maybe<Scalars['ISO8601DateTime']>;
  gender: GenderEnum;
  id: Scalars['ID'];
  lastKana?: Maybe<Scalars['String']>;
  lastName: Scalars['String'];
  lastVisitDate?: Maybe<Scalars['ISO8601DateTime']>;
  medicineReaction?: Maybe<Scalars['Boolean']>;
  mobile?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  patchTest?: Maybe<Scalars['Boolean']>;
  reasonDetail?: Maybe<Scalars['String']>;
  smaregiId: Scalars['ID'];
  tel?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
  visitReason?: Maybe<VisitReasonEnum>;
};

/** The connection type for Customer. */
export type CustomerConnection = {
  __typename?: 'CustomerConnection';
  customers: Array<Customer>;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CustomerEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Customer>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type CustomerEdge = {
  __typename?: 'CustomerEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Customer>;
};

/** Autogenerated input type of DeleteLibrary */
export type DeleteLibraryInput = {
  id: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of DeleteLibrary */
export type DeleteLibraryPayload = {
  __typename?: 'DeleteLibraryPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  result: Scalars['Boolean'];
};

/** Autogenerated input type of DeleteReceipt */
export type DeleteReceiptInput = {
  id: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of DeleteReceipt */
export type DeleteReceiptPayload = {
  __typename?: 'DeleteReceiptPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  result: Scalars['Boolean'];
};

export type Department = ActiveRecordInterface & {
  __typename?: 'Department';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
};

export type Employee_Input = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
};

export type Employee = ActiveRecordInterface & UserInterface & {
  __typename?: 'Employee';
  createdAt: Scalars['ISO8601DateTime'];
  department: Department;
  email: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  rooms: Array<Room>;
  unreadChatExist: Scalars['Boolean'];
  updatedAt: Scalars['ISO8601DateTime'];
};

/** The connection type for Employee. */
export type EmployeeConnection = {
  __typename?: 'EmployeeConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<EmployeeEdge>>>;
  employees: Array<Employee>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Employee>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type EmployeeEdge = {
  __typename?: 'EmployeeEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Employee>;
};

export type FileUrl = {
  __typename?: 'FileUrl';
  fileUrl: Scalars['String'];
};

export enum GenderEnum {
  Unspecified = 'unspecified',
  Male = 'male',
  Female = 'female'
}

export type Instruction_Input = {
  id?: Maybe<Scalars['ID']>;
  employeeId?: Maybe<Scalars['ID']>;
  priority?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  endAt?: Maybe<Scalars['ISO8601DateTime']>;
  file?: Maybe<Scalars['String']>;
  instructionCategoryId?: Maybe<Scalars['ID']>;
};


export type Instruction = ActiveRecordInterface & {
  __typename?: 'Instruction';
  content?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  employee: Employee;
  endAt?: Maybe<Scalars['ISO8601DateTime']>;
  file?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  instructionCategory: InstructionCategory;
  priority: PriorityEnum;
  reports?: Maybe<Array<Report>>;
  reviewedReportCount: Scalars['Int'];
  shopCount: Scalars['Int'];
  shops: Array<Shop>;
  title: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
};

export type InstructionCategory = ActiveRecordInterface & {
  __typename?: 'InstructionCategory';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
};

/** The connection type for Instruction. */
export type InstructionConnection = {
  __typename?: 'InstructionConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<InstructionEdge>>>;
  instructions: Array<Instruction>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Instruction>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type InstructionEdge = {
  __typename?: 'InstructionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Instruction>;
};


export type Library = ActiveRecordInterface & {
  __typename?: 'Library';
  createdAt: Scalars['ISO8601DateTime'];
  fileUrl: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
};

/** The connection type for Library. */
export type LibraryConnection = {
  __typename?: 'LibraryConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<LibraryEdge>>>;
  libraries: Array<Library>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Library>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type LibraryEdge = {
  __typename?: 'LibraryEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Library>;
};

/** Autogenerated input type of Login */
export type LoginInput = {
  email: Scalars['String'];
  password: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of Login */
export type LoginPayload = {
  __typename?: 'LoginPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  isSuccess: Scalars['Boolean'];
  token?: Maybe<Scalars['String']>;
  user?: Maybe<UserInterface>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** create analysis */
  createAnalysis?: Maybe<CreateAnalysisPayload>;
  /** create chat */
  createChat?: Maybe<CreateChatPayload>;
  /** create customor by json */
  createCustomer?: Maybe<CreateCustomerPayload>;
  /** create employee */
  createEmployee?: Maybe<CreateEmployeePayload>;
  /** create instruction */
  createInstruction?: Maybe<CreateInstructionPayload>;
  /** create library */
  createLibrary?: Maybe<CreateLibraryPayload>;
  /** create product by json */
  createProduct?: Maybe<CreateProductPayload>;
  /** create receipt */
  createReceipt?: Maybe<CreateReceiptPayload>;
  /** create report */
  createReport?: Maybe<CreateReportPayload>;
  /** create shop */
  createShop?: Maybe<CreateShopPayload>;
  /** create visit */
  createVisit?: Maybe<CreateVisitPayload>;
  /** delete library */
  deleteLibrary?: Maybe<DeleteLibraryPayload>;
  /** delete receipt */
  deleteReceipt?: Maybe<DeleteReceiptPayload>;
  /** login query */
  login?: Maybe<LoginPayload>;
  /** read chat */
  readRoomChats?: Maybe<ReadRoomChatsPayload>;
  /** update customer */
  updateCustomer?: Maybe<UpdateCustomerPayload>;
  /** update employee */
  updateEmployee?: Maybe<UpdateEmployeePayload>;
  /** update instruction */
  updateInstruction?: Maybe<UpdateInstructionPayload>;
  /** update receipt */
  updateReceipt?: Maybe<UpdateReceiptPayload>;
  /** create report */
  updateReportStatus?: Maybe<UpdateReportStatusPayload>;
  /** update sales target daily by month */
  updateSalesTargetDailyByMonth?: Maybe<UpdateSalesTargetDailyByMonthPayload>;
  /** update shop */
  updateShop?: Maybe<UpdateShopPayload>;
  /** update staff */
  updateStaff?: Maybe<UpdateStaffPayload>;
  /** update visit */
  updateVisit?: Maybe<UpdateVisitPayload>;
};


export type MutationCreateAnalysisArgs = {
  input: CreateAnalysisInput;
};


export type MutationCreateChatArgs = {
  input: CreateChatInput;
};


export type MutationCreateCustomerArgs = {
  input: CreateCustomerInput;
};


export type MutationCreateEmployeeArgs = {
  input: CreateEmployeeInput;
};


export type MutationCreateInstructionArgs = {
  input: CreateInstructionInput;
};


export type MutationCreateLibraryArgs = {
  input: CreateLibraryInput;
};


export type MutationCreateProductArgs = {
  input: CreateProductInput;
};


export type MutationCreateReceiptArgs = {
  input: CreateReceiptInput;
};


export type MutationCreateReportArgs = {
  input: CreateReportInput;
};


export type MutationCreateShopArgs = {
  input: CreateShopInput;
};


export type MutationCreateVisitArgs = {
  input: CreateVisitInput;
};


export type MutationDeleteLibraryArgs = {
  input: DeleteLibraryInput;
};


export type MutationDeleteReceiptArgs = {
  input: DeleteReceiptInput;
};


export type MutationLoginArgs = {
  input: LoginInput;
};


export type MutationReadRoomChatsArgs = {
  input: ReadRoomChatsInput;
};


export type MutationUpdateCustomerArgs = {
  input: UpdateCustomerInput;
};


export type MutationUpdateEmployeeArgs = {
  input: UpdateEmployeeInput;
};


export type MutationUpdateInstructionArgs = {
  input: UpdateInstructionInput;
};


export type MutationUpdateReceiptArgs = {
  input: UpdateReceiptInput;
};


export type MutationUpdateReportStatusArgs = {
  input: UpdateReportStatusInput;
};


export type MutationUpdateSalesTargetDailyByMonthArgs = {
  input: UpdateSalesTargetDailyByMonthInput;
};


export type MutationUpdateShopArgs = {
  input: UpdateShopInput;
};


export type MutationUpdateStaffArgs = {
  input: UpdateStaffInput;
};


export type MutationUpdateVisitArgs = {
  input: UpdateVisitInput;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

export enum PriorityEnum {
  Unset = 'unset',
  High = 'high'
}

export type Product = ActiveRecordInterface & {
  __typename?: 'Product';
  category: ProductCategoryEnum;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export enum ProductCategoryEnum {
  Treatment = 'treatment',
  Dye = 'dye',
  Goods = 'goods'
}

export type Query = {
  __typename?: 'Query';
  /** chat users */
  chatUsers: ChatUserConnection;
  /** Customer */
  customer: Customer;
  /** customers */
  customers: CustomerConnection;
  /** employee detail */
  employee: Employee;
  /** employee lists */
  employees: EmployeeConnection;
  /** instruction */
  instruction: Instruction;
  /** instruction categories */
  instructionCategories: Array<InstructionCategory>;
  /** All instructions */
  instructions: InstructionConnection;
  /** instructions on home */
  instructionsOnHome: InstructionConnection;
  /** Libraries */
  libraries: LibraryConnection;
  /** get s3 download presign */
  presignDownload: FileUrl;
  /** get s3 upload presign */
  presignUpload: AwsUpload;
  /** products */
  products: Array<Product>;
  /** Reports */
  report: Report;
  /** Reports */
  reports: ReportConnection;
  /** Reports on home */
  reportsOnHome: ReportConnection;
  /** room chats */
  roomChats: ChatConnection;
  /** Room */
  rooms: RoomConnection;
  /** sales analyses query */
  salesAnalyses: Array<SalesAnalyses>;
  /** shop detail query */
  shop: Shop;
  /** shop list query */
  shops: ShopConnection;
  /** Transaction */
  transaction: Array<Transactiondetail>;
  /** Transaction */
  transactions: Array<Transactionlist>;
  /** find auth user */
  user: UserInterface;
};


export type QueryChatUsersArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryCustomerArgs = {
  id: Scalars['ID'];
};


export type QueryCustomersArgs = {
  name?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryEmployeeArgs = {
  id: Scalars['ID'];
};


export type QueryEmployeesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryInstructionArgs = {
  id: Scalars['ID'];
};


export type QueryInstructionsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryInstructionsOnHomeArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryLibrariesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryPresignDownloadArgs = {
  fileUrl: Scalars['String'];
};


export type QueryPresignUploadArgs = {
  fileName: Scalars['String'];
  klass: Scalars['String'];
};


export type QueryReportArgs = {
  id: Scalars['ID'];
};


export type QueryReportsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryReportsOnHomeArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryRoomChatsArgs = {
  userId: Scalars['ID'];
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryRoomsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QuerySalesAnalysesArgs = {
  startAt: Scalars['ISO8601DateTime'];
  endAt: Scalars['ISO8601DateTime'];
};


export type QueryShopArgs = {
  id: Scalars['ID'];
};


export type QueryShopsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryTransactionArgs = {
  transactionHeadId: Scalars['ID'];
};


export type QueryTransactionsArgs = {
  customerSmaregiId: Scalars['ID'];
  startDate?: Maybe<Scalars['ISO8601DateTime']>;
};

export type Receipt_Input = {
  id?: Maybe<Scalars['ID']>;
  visitId?: Maybe<Scalars['ID']>;
  productId: Scalars['ID'];
  quantity?: Maybe<Scalars['Int']>;
};

export type Report_Input = {
  content?: Maybe<Scalars['String']>;
  instructionId?: Maybe<Scalars['ID']>;
  shopId?: Maybe<Scalars['ID']>;
  attachedFile?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of ReadRoomChats */
export type ReadRoomChatsInput = {
  userId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of ReadRoomChats */
export type ReadRoomChatsPayload = {
  __typename?: 'ReadRoomChatsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  unreadChatExist: Scalars['Boolean'];
};

export type Receipt = {
  __typename?: 'Receipt';
  price?: Maybe<Scalars['Int']>;
  productName?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  transactionDateTime?: Maybe<Scalars['String']>;
  transactionDetailId: Scalars['ID'];
  transactionHeadId?: Maybe<Scalars['ID']>;
};

export type Report = ActiveRecordInterface & {
  __typename?: 'Report';
  attachedFile?: Maybe<Scalars['String']>;
  content: Scalars['String'];
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  instruction: Instruction;
  resubmitReason?: Maybe<Scalars['String']>;
  shop: Shop;
  status: ReportStatusEnum;
  updatedAt: Scalars['ISO8601DateTime'];
};

/** The connection type for Report. */
export type ReportConnection = {
  __typename?: 'ReportConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ReportEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Report>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  reports: Array<Report>;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type ReportEdge = {
  __typename?: 'ReportEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Report>;
};

export enum ReportStatusEnum {
  Unsubmitted = 'unsubmitted',
  Unreviewed = 'unreviewed',
  Reviewed = 'reviewed',
  ResubmitRequest = 'resubmit_request'
}

export type Room = ActiveRecordInterface & {
  __typename?: 'Room';
  createdAt: Scalars['ISO8601DateTime'];
  employee: Employee;
  id: Scalars['ID'];
  shop: Shop;
  unreadChatCount: Scalars['Int'];
  updatedAt: Scalars['ISO8601DateTime'];
};

/** The connection type for Room. */
export type RoomConnection = {
  __typename?: 'RoomConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<RoomEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Room>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  rooms: Array<Room>;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type RoomEdge = {
  __typename?: 'RoomEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Room>;
};

export type Shop_Input = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<ShopStatusEnum>;
  smaregiId?: Maybe<Scalars['Int']>;
};

export type Staff_Input = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
};

export type SalesAnalyses = {
  __typename?: 'SalesAnalyses';
  costTotal: Scalars['Int'];
  costTotalRatio: Scalars['Float'];
  grossMargin: Scalars['Int'];
  grossMarginRatio: Scalars['Float'];
  salesTargetDaily: Scalars['Int'];
  salesTargetDailyRatio: Scalars['Float'];
  salesTotal: Scalars['Int'];
  shop: Shop;
};

export type Shop = ActiveRecordInterface & {
  __typename?: 'Shop';
  createdAt: Scalars['ISO8601DateTime'];
  customers?: Maybe<Array<Customer>>;
  id: Scalars['ID'];
  name: Scalars['String'];
  reports?: Maybe<Array<Report>>;
  rooms: Array<Room>;
  smaregiId: Scalars['Int'];
  staffs?: Maybe<Array<Staff>>;
  status: ShopStatusEnum;
  updatedAt: Scalars['ISO8601DateTime'];
  visits?: Maybe<Array<Visit>>;
};

/** The connection type for Shop. */
export type ShopConnection = {
  __typename?: 'ShopConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ShopEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Shop>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  shops: Array<Shop>;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type ShopEdge = {
  __typename?: 'ShopEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Shop>;
};

export enum ShopStatusEnum {
  Deleted = 'deleted',
  Created = 'created'
}

export type Staff = ActiveRecordInterface & UserInterface & {
  __typename?: 'Staff';
  createdAt: Scalars['ISO8601DateTime'];
  email: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  shop: Shop;
  unreadChatExist: Scalars['Boolean'];
  updatedAt: Scalars['ISO8601DateTime'];
};

export type Transactiondetail = {
  __typename?: 'Transactiondetail';
  price?: Maybe<Scalars['Int']>;
  productName?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  transactionDateTime?: Maybe<Scalars['String']>;
  transactionDetailId: Scalars['ID'];
};

export type Transactionlist = {
  __typename?: 'Transactionlist';
  amount: Scalars['Int'];
  returnAmount: Scalars['Int'];
  total: Scalars['Int'];
  transactionDateTime: Scalars['String'];
  transactionHeadId: Scalars['ID'];
};

/** Autogenerated input type of UpdateCustomer */
export type UpdateCustomerInput = {
  customerInput: Customer_Input;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateCustomer */
export type UpdateCustomerPayload = {
  __typename?: 'UpdateCustomerPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  customer: Customer;
};

/** Autogenerated input type of UpdateEmployee */
export type UpdateEmployeeInput = {
  employeeInput: Employee_Input;
  currentPassword: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateEmployee */
export type UpdateEmployeePayload = {
  __typename?: 'UpdateEmployeePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  employee?: Maybe<Employee>;
};

/** Autogenerated input type of UpdateInstruction */
export type UpdateInstructionInput = {
  instructionInput: Instruction_Input;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateInstruction */
export type UpdateInstructionPayload = {
  __typename?: 'UpdateInstructionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  result: Scalars['Boolean'];
};

/** Autogenerated input type of UpdateReceipt */
export type UpdateReceiptInput = {
  receiptInput: Receipt_Input;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateReceipt */
export type UpdateReceiptPayload = {
  __typename?: 'UpdateReceiptPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  receipt: Receipt;
};

/** Autogenerated input type of UpdateReportStatus */
export type UpdateReportStatusInput = {
  reportId: Scalars['ID'];
  status: ReportStatusEnum;
  resubmitReason?: Maybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateReportStatus */
export type UpdateReportStatusPayload = {
  __typename?: 'UpdateReportStatusPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  report: Report;
};

/** Autogenerated input type of UpdateSalesTargetDailyByMonth */
export type UpdateSalesTargetDailyByMonthInput = {
  shopSmaregiId?: Maybe<Scalars['ID']>;
  year: Scalars['Int'];
  month: Scalars['Int'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateSalesTargetDailyByMonth */
export type UpdateSalesTargetDailyByMonthPayload = {
  __typename?: 'UpdateSalesTargetDailyByMonthPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  result: Scalars['Boolean'];
};

/** Autogenerated input type of UpdateShop */
export type UpdateShopInput = {
  shopInput: Shop_Input;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateShop */
export type UpdateShopPayload = {
  __typename?: 'UpdateShopPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  shop: Shop;
};

/** Autogenerated input type of UpdateStaff */
export type UpdateStaffInput = {
  staffInput: Staff_Input;
  currentPassword: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateStaff */
export type UpdateStaffPayload = {
  __typename?: 'UpdateStaffPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  staff?: Maybe<Staff>;
};

/** Autogenerated input type of UpdateVisit */
export type UpdateVisitInput = {
  visitInput: Visit_Input;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateVisit */
export type UpdateVisitPayload = {
  __typename?: 'UpdateVisitPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  visit: Visit;
};

/** user(staff and employee) interface */
export type UserInterface = {
  createdAt: Scalars['ISO8601DateTime'];
  email: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  unreadChatExist: Scalars['Boolean'];
  updatedAt: Scalars['ISO8601DateTime'];
};

export type Visit_Input = {
  id?: Maybe<Scalars['ID']>;
  customerId?: Maybe<Scalars['ID']>;
  shopId?: Maybe<Scalars['ID']>;
  status?: Maybe<Scalars['String']>;
  bookDate?: Maybe<Scalars['ISO8601DateTime']>;
};

export type Visit = ActiveRecordInterface & {
  __typename?: 'Visit';
  bookDate: Scalars['ISO8601DateTime'];
  createdAt: Scalars['ISO8601DateTime'];
  customer: Customer;
  id: Scalars['ID'];
  receipts: Array<Receipt>;
  shop: Shop;
  status: VisitStatusEnum;
  updatedAt: Scalars['ISO8601DateTime'];
};

export enum VisitReasonEnum {
  Flyer = 'flyer',
  Hotpepper = 'hotpepper',
  Introduction = 'introduction',
  Advertise = 'advertise',
  Others = 'others'
}

export enum VisitStatusEnum {
  Canceled = 'canceled',
  Booked = 'booked',
  Visited = 'visited'
}

export type CreateAnalysisMutationVariables = Exact<{
  json: Scalars['JSON'];
}>;


export type CreateAnalysisMutation = (
  { __typename?: 'Mutation' }
  & { createAnalysis?: Maybe<(
    { __typename?: 'CreateAnalysisPayload' }
    & Pick<CreateAnalysisPayload, 'result'>
  )> }
);

export type CreateChatMutationVariables = Exact<{
  content?: Maybe<Scalars['String']>;
  receiverId: Scalars['ID'];
  fileName?: Maybe<Scalars['String']>;
  fileUrl?: Maybe<Scalars['String']>;
}>;


export type CreateChatMutation = (
  { __typename?: 'Mutation' }
  & { createChat?: Maybe<(
    { __typename?: 'CreateChatPayload' }
    & { chat: (
      { __typename?: 'Chat' }
      & Pick<Chat, 'id' | 'content' | 'senderId' | 'createdAt' | 'fileUrl' | 'fileName'>
    ) }
  )> }
);

export type CreateCustomerMutationVariables = Exact<{
  json: Scalars['JSON'];
}>;


export type CreateCustomerMutation = (
  { __typename?: 'Mutation' }
  & { createCustomer?: Maybe<(
    { __typename?: 'CreateCustomerPayload' }
    & Pick<CreateCustomerPayload, 'result' | 'error'>
  )> }
);

export type CreateEmployeeMutationVariables = Exact<{
  input: Employee_Input;
}>;


export type CreateEmployeeMutation = (
  { __typename?: 'Mutation' }
  & { createEmployee?: Maybe<(
    { __typename?: 'CreateEmployeePayload' }
    & { employee: (
      { __typename?: 'Employee' }
      & Pick<Employee, 'id' | 'name' | 'email' | 'createdAt' | 'updatedAt'>
    ) }
  )> }
);

export type CreateInstructionMutationVariables = Exact<{
  instructionInput: Instruction_Input;
  shopsInput?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;


export type CreateInstructionMutation = (
  { __typename?: 'Mutation' }
  & { createInstruction?: Maybe<(
    { __typename?: 'CreateInstructionPayload' }
    & Pick<CreateInstructionPayload, 'result'>
  )> }
);

export type CreateLibraryMutationVariables = Exact<{
  name: Scalars['String'];
  fileUrl: Scalars['String'];
}>;


export type CreateLibraryMutation = (
  { __typename?: 'Mutation' }
  & { createLibrary?: Maybe<(
    { __typename?: 'CreateLibraryPayload' }
    & { library: (
      { __typename?: 'Library' }
      & Pick<Library, 'id' | 'name' | 'fileUrl'>
    ) }
  )> }
);

export type CreateProductMutationVariables = Exact<{
  json: Scalars['JSON'];
}>;


export type CreateProductMutation = (
  { __typename?: 'Mutation' }
  & { createProduct?: Maybe<(
    { __typename?: 'CreateProductPayload' }
    & Pick<CreateProductPayload, 'result' | 'error'>
  )> }
);

export type CreateReceiptMutationVariables = Exact<{
  json: Scalars['JSON'];
}>;


export type CreateReceiptMutation = (
  { __typename?: 'Mutation' }
  & { createReceipt?: Maybe<(
    { __typename?: 'CreateReceiptPayload' }
    & Pick<CreateReceiptPayload, 'result' | 'errors'>
  )> }
);

export type CreateReportMutationVariables = Exact<{
  input: Report_Input;
}>;


export type CreateReportMutation = (
  { __typename?: 'Mutation' }
  & { createReport?: Maybe<(
    { __typename?: 'CreateReportPayload' }
    & { report: (
      { __typename?: 'Report' }
      & Pick<Report, 'id'>
    ) }
  )> }
);

export type CreateShopMutationVariables = Exact<{
  input: Shop_Input;
  staffPassword: Scalars['String'];
}>;


export type CreateShopMutation = (
  { __typename?: 'Mutation' }
  & { createShop?: Maybe<(
    { __typename?: 'CreateShopPayload' }
    & { shop: (
      { __typename?: 'Shop' }
      & Pick<Shop, 'id' | 'name' | 'createdAt' | 'updatedAt'>
    ) }
  )> }
);

export type DeleteLibraryMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteLibraryMutation = (
  { __typename?: 'Mutation' }
  & { deleteLibrary?: Maybe<(
    { __typename?: 'DeleteLibraryPayload' }
    & Pick<DeleteLibraryPayload, 'result'>
  )> }
);

export type LoginMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { login?: Maybe<(
    { __typename?: 'LoginPayload' }
    & Pick<LoginPayload, 'isSuccess' | 'token'>
    & { user?: Maybe<(
      { __typename?: 'Employee' }
      & Pick<Employee, 'id' | 'name' | 'email' | 'updatedAt' | 'createdAt' | 'unreadChatExist'>
    ) | (
      { __typename?: 'Staff' }
      & Pick<Staff, 'id' | 'name' | 'email' | 'updatedAt' | 'createdAt' | 'unreadChatExist'>
      & { shop: (
        { __typename?: 'Shop' }
        & Pick<Shop, 'id' | 'name'>
      ) }
    )> }
  )> }
);

export type ReadRoomChatsMutationVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type ReadRoomChatsMutation = (
  { __typename?: 'Mutation' }
  & { readRoomChats?: Maybe<(
    { __typename?: 'ReadRoomChatsPayload' }
    & Pick<ReadRoomChatsPayload, 'unreadChatExist'>
  )> }
);

export type UpdateCustomerMutationVariables = Exact<{
  input: Customer_Input;
}>;


export type UpdateCustomerMutation = (
  { __typename?: 'Mutation' }
  & { updateCustomer?: Maybe<(
    { __typename?: 'UpdateCustomerPayload' }
    & { customer: (
      { __typename?: 'Customer' }
      & Pick<Customer, 'id' | 'lastName' | 'firstName' | 'lastKana' | 'firstKana' | 'birthDate' | 'gender' | 'address' | 'tel' | 'note' | 'createdAt' | 'updatedAt'>
    ) }
  )> }
);

export type UpdateEmployeeMutationVariables = Exact<{
  input: Employee_Input;
  currentPassword: Scalars['String'];
}>;


export type UpdateEmployeeMutation = (
  { __typename?: 'Mutation' }
  & { updateEmployee?: Maybe<(
    { __typename?: 'UpdateEmployeePayload' }
    & { employee?: Maybe<(
      { __typename?: 'Employee' }
      & Pick<Employee, 'id' | 'name' | 'email'>
    )> }
  )> }
);

export type UpdateInstructionMutationVariables = Exact<{
  instructionInput: Instruction_Input;
}>;


export type UpdateInstructionMutation = (
  { __typename?: 'Mutation' }
  & { updateInstruction?: Maybe<(
    { __typename?: 'UpdateInstructionPayload' }
    & Pick<UpdateInstructionPayload, 'result'>
  )> }
);

export type UpdateReportStatusMutationVariables = Exact<{
  reportId: Scalars['ID'];
  status: ReportStatusEnum;
  resubmitReason?: Maybe<Scalars['String']>;
}>;


export type UpdateReportStatusMutation = (
  { __typename?: 'Mutation' }
  & { updateReportStatus?: Maybe<(
    { __typename?: 'UpdateReportStatusPayload' }
    & { report: (
      { __typename?: 'Report' }
      & Pick<Report, 'id'>
    ) }
  )> }
);

export type UpdateShopMutationVariables = Exact<{
  input: Shop_Input;
}>;


export type UpdateShopMutation = (
  { __typename?: 'Mutation' }
  & { updateShop?: Maybe<(
    { __typename?: 'UpdateShopPayload' }
    & { shop: (
      { __typename?: 'Shop' }
      & Pick<Shop, 'id' | 'name'>
    ) }
  )> }
);

export type UpdateStaffMutationVariables = Exact<{
  input: Staff_Input;
  currentPassword: Scalars['String'];
}>;


export type UpdateStaffMutation = (
  { __typename?: 'Mutation' }
  & { updateStaff?: Maybe<(
    { __typename?: 'UpdateStaffPayload' }
    & { staff?: Maybe<(
      { __typename?: 'Staff' }
      & Pick<Staff, 'id' | 'name' | 'email'>
    )> }
  )> }
);

export type ChatUsersQueryVariables = Exact<{
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
}>;


export type ChatUsersQuery = (
  { __typename?: 'Query' }
  & { chatUsers: (
    { __typename?: 'ChatUserConnection' }
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage' | 'startCursor' | 'hasPreviousPage'>
    ), edges?: Maybe<Array<Maybe<(
      { __typename?: 'ChatUserEdge' }
      & Pick<ChatUserEdge, 'cursor'>
      & { node?: Maybe<(
        { __typename?: 'ChatUser' }
        & Pick<ChatUser, 'id' | 'name' | 'unreadCount'>
      )> }
    )>>> }
  ) }
);

export type CustomerQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CustomerQuery = (
  { __typename?: 'Query' }
  & { customer: (
    { __typename?: 'Customer' }
    & Pick<Customer, 'id' | 'lastName' | 'firstName' | 'lastKana' | 'firstKana' | 'birthDate' | 'gender' | 'address' | 'tel' | 'mobile' | 'visitReason' | 'reasonDetail' | 'medicineReaction' | 'patchTest' | 'note' | 'createdAt' | 'updatedAt' | 'lastVisitDate' | 'firstVisitDate' | 'smaregiId'>
  ) }
);

export type CustomersQueryVariables = Exact<{
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
}>;


export type CustomersQuery = (
  { __typename?: 'Query' }
  & { customers: (
    { __typename?: 'CustomerConnection' }
    & Pick<CustomerConnection, 'totalCount'>
    & { customers: Array<(
      { __typename?: 'Customer' }
      & Pick<Customer, 'id' | 'lastName' | 'firstName' | 'lastKana' | 'firstKana' | 'birthDate' | 'gender' | 'address' | 'tel' | 'note' | 'createdAt' | 'updatedAt' | 'lastVisitDate' | 'firstVisitDate'>
    )> }
  ) }
);

export type EmployeesQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
}>;


export type EmployeesQuery = (
  { __typename?: 'Query' }
  & { employees: (
    { __typename?: 'EmployeeConnection' }
    & Pick<EmployeeConnection, 'totalCount'>
    & { employees: Array<(
      { __typename?: 'Employee' }
      & Pick<Employee, 'id' | 'name' | 'email'>
    )> }
  ) }
);

export type InstructionQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type InstructionQuery = (
  { __typename?: 'Query' }
  & { instruction: (
    { __typename?: 'Instruction' }
    & Pick<Instruction, 'id' | 'priority' | 'title' | 'endAt' | 'createdAt' | 'content' | 'file' | 'reviewedReportCount' | 'shopCount'>
    & { employee: (
      { __typename?: 'Employee' }
      & Pick<Employee, 'id' | 'name'>
    ), instructionCategory: (
      { __typename?: 'InstructionCategory' }
      & Pick<InstructionCategory, 'id' | 'name'>
    ), reports?: Maybe<Array<(
      { __typename?: 'Report' }
      & Pick<Report, 'id' | 'status'>
      & { shop: (
        { __typename?: 'Shop' }
        & Pick<Shop, 'id' | 'name'>
      ) }
    )>>, shops: Array<(
      { __typename?: 'Shop' }
      & Pick<Shop, 'id' | 'name'>
    )> }
  ) }
);

export type InstructionCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type InstructionCategoriesQuery = (
  { __typename?: 'Query' }
  & { instructionCategories: Array<(
    { __typename?: 'InstructionCategory' }
    & Pick<InstructionCategory, 'id' | 'name'>
  )> }
);

export type InstructionsQueryVariables = Exact<{
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
}>;


export type InstructionsQuery = (
  { __typename?: 'Query' }
  & { instructions: (
    { __typename?: 'InstructionConnection' }
    & Pick<InstructionConnection, 'totalCount'>
    & { instructions: Array<(
      { __typename?: 'Instruction' }
      & Pick<Instruction, 'id' | 'priority' | 'title' | 'createdAt' | 'endAt' | 'reviewedReportCount' | 'shopCount'>
      & { employee: (
        { __typename?: 'Employee' }
        & Pick<Employee, 'id' | 'name'>
      ), instructionCategory: (
        { __typename?: 'InstructionCategory' }
        & Pick<InstructionCategory, 'id' | 'name'>
      ) }
    )> }
  ) }
);

export type InstructionsOnHomeQueryVariables = Exact<{
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
}>;


export type InstructionsOnHomeQuery = (
  { __typename?: 'Query' }
  & { instructionsOnHome: (
    { __typename?: 'InstructionConnection' }
    & Pick<InstructionConnection, 'totalCount'>
    & { instructions: Array<(
      { __typename?: 'Instruction' }
      & Pick<Instruction, 'id' | 'priority' | 'title' | 'createdAt' | 'reviewedReportCount' | 'shopCount'>
      & { employee: (
        { __typename?: 'Employee' }
        & Pick<Employee, 'id' | 'name'>
      ), instructionCategory: (
        { __typename?: 'InstructionCategory' }
        & Pick<InstructionCategory, 'id' | 'name'>
      ) }
    )> }
  ) }
);

export type LibrariesQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
}>;


export type LibrariesQuery = (
  { __typename?: 'Query' }
  & { libraries: (
    { __typename?: 'LibraryConnection' }
    & Pick<LibraryConnection, 'totalCount'>
    & { libraries: Array<(
      { __typename?: 'Library' }
      & Pick<Library, 'id' | 'name' | 'fileUrl' | 'createdAt'>
    )> }
  ) }
);

export type PresignDownloadQueryVariables = Exact<{
  fileUrl: Scalars['String'];
}>;


export type PresignDownloadQuery = (
  { __typename?: 'Query' }
  & { presignDownload: (
    { __typename?: 'FileUrl' }
    & Pick<FileUrl, 'fileUrl'>
  ) }
);

export type PresignUploadQueryVariables = Exact<{
  fileName: Scalars['String'];
  klass: Scalars['String'];
}>;


export type PresignUploadQuery = (
  { __typename?: 'Query' }
  & { presignUpload: (
    { __typename?: 'AwsUpload' }
    & Pick<AwsUpload, 'url' | 'fields'>
  ) }
);

export type ReportQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ReportQuery = (
  { __typename?: 'Query' }
  & { report: (
    { __typename?: 'Report' }
    & Pick<Report, 'id' | 'content' | 'createdAt' | 'updatedAt' | 'attachedFile' | 'status'>
    & { shop: (
      { __typename?: 'Shop' }
      & Pick<Shop, 'id' | 'name'>
    ), instruction: (
      { __typename?: 'Instruction' }
      & Pick<Instruction, 'id' | 'title' | 'priority'>
      & { instructionCategory: (
        { __typename?: 'InstructionCategory' }
        & Pick<InstructionCategory, 'id' | 'name'>
      ) }
    ) }
  ) }
);

export type ReportsQueryVariables = Exact<{
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
}>;


export type ReportsQuery = (
  { __typename?: 'Query' }
  & { reports: (
    { __typename?: 'ReportConnection' }
    & Pick<ReportConnection, 'totalCount'>
    & { reports: Array<(
      { __typename?: 'Report' }
      & Pick<Report, 'id' | 'content' | 'status' | 'createdAt' | 'updatedAt' | 'attachedFile' | 'resubmitReason'>
      & { shop: (
        { __typename?: 'Shop' }
        & Pick<Shop, 'id' | 'name'>
      ), instruction: (
        { __typename?: 'Instruction' }
        & Pick<Instruction, 'id' | 'title' | 'priority' | 'endAt'>
        & { instructionCategory: (
          { __typename?: 'InstructionCategory' }
          & Pick<InstructionCategory, 'id' | 'name'>
        ) }
      ) }
    )> }
  ) }
);

export type ReportsOnHomeQueryVariables = Exact<{
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
}>;


export type ReportsOnHomeQuery = (
  { __typename?: 'Query' }
  & { reportsOnHome: (
    { __typename?: 'ReportConnection' }
    & Pick<ReportConnection, 'totalCount'>
    & { reports: Array<(
      { __typename?: 'Report' }
      & Pick<Report, 'id' | 'content' | 'status' | 'createdAt' | 'updatedAt' | 'attachedFile' | 'resubmitReason'>
      & { shop: (
        { __typename?: 'Shop' }
        & Pick<Shop, 'id' | 'name'>
      ), instruction: (
        { __typename?: 'Instruction' }
        & Pick<Instruction, 'id' | 'title' | 'priority' | 'endAt'>
        & { instructionCategory: (
          { __typename?: 'InstructionCategory' }
          & Pick<InstructionCategory, 'id' | 'name'>
        ) }
      ) }
    )> }
  ) }
);

export type RoomChatsQueryVariables = Exact<{
  userId: Scalars['ID'];
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
}>;


export type RoomChatsQuery = (
  { __typename?: 'Query' }
  & { roomChats: (
    { __typename?: 'ChatConnection' }
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage' | 'startCursor' | 'hasPreviousPage' | 'endCursor'>
    ), edges?: Maybe<Array<Maybe<(
      { __typename?: 'ChatEdge' }
      & Pick<ChatEdge, 'cursor'>
      & { node?: Maybe<(
        { __typename?: 'Chat' }
        & Pick<Chat, 'id' | 'roomId' | 'content' | 'senderId' | 'fileUrl' | 'fileName' | 'createdAt'>
      )> }
    )>>> }
  ) }
);

export type RoomsQueryVariables = Exact<{
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
}>;


export type RoomsQuery = (
  { __typename?: 'Query' }
  & { rooms: (
    { __typename?: 'RoomConnection' }
    & Pick<RoomConnection, 'totalCount'>
    & { rooms: Array<(
      { __typename?: 'Room' }
      & Pick<Room, 'id' | 'createdAt' | 'updatedAt'>
      & { shop: (
        { __typename?: 'Shop' }
        & Pick<Shop, 'id' | 'name'>
      ), employee: (
        { __typename?: 'Employee' }
        & Pick<Employee, 'id' | 'name'>
      ) }
    )> }
  ) }
);

export type SalesAnalysesQueryVariables = Exact<{
  startAt: Scalars['ISO8601DateTime'];
  endAt: Scalars['ISO8601DateTime'];
}>;


export type SalesAnalysesQuery = (
  { __typename?: 'Query' }
  & { salesAnalyses: Array<(
    { __typename?: 'SalesAnalyses' }
    & Pick<SalesAnalyses, 'salesTotal' | 'costTotal' | 'costTotalRatio' | 'grossMargin' | 'grossMarginRatio' | 'salesTargetDaily' | 'salesTargetDailyRatio'>
    & { shop: (
      { __typename?: 'Shop' }
      & Pick<Shop, 'id' | 'name'>
    ) }
  )> }
);

export type ShopQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ShopQuery = (
  { __typename?: 'Query' }
  & { shop: (
    { __typename?: 'Shop' }
    & Pick<Shop, 'id' | 'name' | 'status' | 'smaregiId'>
    & { staffs?: Maybe<Array<(
      { __typename?: 'Staff' }
      & Pick<Staff, 'id' | 'name' | 'email'>
    )>>, reports?: Maybe<Array<(
      { __typename?: 'Report' }
      & Pick<Report, 'id' | 'content' | 'status'>
      & { instruction: (
        { __typename?: 'Instruction' }
        & Pick<Instruction, 'id' | 'title' | 'content'>
        & { instructionCategory: (
          { __typename?: 'InstructionCategory' }
          & Pick<InstructionCategory, 'id' | 'name'>
        ) }
      ) }
    )>>, customers?: Maybe<Array<(
      { __typename?: 'Customer' }
      & Pick<Customer, 'id' | 'lastName' | 'firstName'>
    )>> }
  ) }
);

export type ShopsQueryVariables = Exact<{
  ids?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
}>;


export type ShopsQuery = (
  { __typename?: 'Query' }
  & { shops: (
    { __typename?: 'ShopConnection' }
    & Pick<ShopConnection, 'totalCount'>
    & { shops: Array<(
      { __typename?: 'Shop' }
      & Pick<Shop, 'id' | 'name'>
    )> }
  ) }
);

export type TransactionQueryVariables = Exact<{
  transactionHeadId: Scalars['ID'];
}>;


export type TransactionQuery = (
  { __typename?: 'Query' }
  & { transaction: Array<(
    { __typename?: 'Transactiondetail' }
    & Pick<Transactiondetail, 'productName' | 'price' | 'quantity' | 'transactionDateTime' | 'transactionDetailId'>
  )> }
);

export type TransactionsQueryVariables = Exact<{
  customerSmaregiId: Scalars['ID'];
  startDate: Scalars['ISO8601DateTime'];
}>;


export type TransactionsQuery = (
  { __typename?: 'Query' }
  & { transactions: Array<(
    { __typename?: 'Transactionlist' }
    & Pick<Transactionlist, 'transactionHeadId' | 'transactionDateTime' | 'amount' | 'returnAmount' | 'total'>
  )> }
);

export type UserQueryVariables = Exact<{ [key: string]: never; }>;


export type UserQuery = (
  { __typename?: 'Query' }
  & { user: (
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'name' | 'email' | 'createdAt' | 'updatedAt' | 'unreadChatExist'>
  ) | (
    { __typename?: 'Staff' }
    & Pick<Staff, 'id' | 'name' | 'email' | 'createdAt' | 'updatedAt' | 'unreadChatExist'>
    & { shop: (
      { __typename?: 'Shop' }
      & Pick<Shop, 'id' | 'name'>
    ) }
  ) }
);


export const CreateAnalysisDocument = gql`
    mutation createAnalysis($json: JSON!) {
  createAnalysis(input: {json: $json}) {
    result
  }
}
    `;
export type CreateAnalysisMutationFn = Apollo.MutationFunction<CreateAnalysisMutation, CreateAnalysisMutationVariables>;

/**
 * __useCreateAnalysisMutation__
 *
 * To run a mutation, you first call `useCreateAnalysisMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAnalysisMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAnalysisMutation, { data, loading, error }] = useCreateAnalysisMutation({
 *   variables: {
 *      json: // value for 'json'
 *   },
 * });
 */
export function useCreateAnalysisMutation(baseOptions?: Apollo.MutationHookOptions<CreateAnalysisMutation, CreateAnalysisMutationVariables>) {
        return Apollo.useMutation<CreateAnalysisMutation, CreateAnalysisMutationVariables>(CreateAnalysisDocument, baseOptions);
      }
export type CreateAnalysisMutationHookResult = ReturnType<typeof useCreateAnalysisMutation>;
export type CreateAnalysisMutationResult = Apollo.MutationResult<CreateAnalysisMutation>;
export type CreateAnalysisMutationOptions = Apollo.BaseMutationOptions<CreateAnalysisMutation, CreateAnalysisMutationVariables>;
export const CreateChatDocument = gql`
    mutation createChat($content: String, $receiverId: ID!, $fileName: String, $fileUrl: String) {
  createChat(
    input: {receiverId: $receiverId, content: $content, fileName: $fileName, fileUrl: $fileUrl}
  ) {
    chat {
      id
      content
      senderId
      createdAt
      fileUrl
      fileName
    }
  }
}
    `;
export type CreateChatMutationFn = Apollo.MutationFunction<CreateChatMutation, CreateChatMutationVariables>;

/**
 * __useCreateChatMutation__
 *
 * To run a mutation, you first call `useCreateChatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateChatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createChatMutation, { data, loading, error }] = useCreateChatMutation({
 *   variables: {
 *      content: // value for 'content'
 *      receiverId: // value for 'receiverId'
 *      fileName: // value for 'fileName'
 *      fileUrl: // value for 'fileUrl'
 *   },
 * });
 */
export function useCreateChatMutation(baseOptions?: Apollo.MutationHookOptions<CreateChatMutation, CreateChatMutationVariables>) {
        return Apollo.useMutation<CreateChatMutation, CreateChatMutationVariables>(CreateChatDocument, baseOptions);
      }
export type CreateChatMutationHookResult = ReturnType<typeof useCreateChatMutation>;
export type CreateChatMutationResult = Apollo.MutationResult<CreateChatMutation>;
export type CreateChatMutationOptions = Apollo.BaseMutationOptions<CreateChatMutation, CreateChatMutationVariables>;
export const CreateCustomerDocument = gql`
    mutation createCustomer($json: JSON!) {
  createCustomer(input: {json: $json}) {
    result
    error
  }
}
    `;
export type CreateCustomerMutationFn = Apollo.MutationFunction<CreateCustomerMutation, CreateCustomerMutationVariables>;

/**
 * __useCreateCustomerMutation__
 *
 * To run a mutation, you first call `useCreateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomerMutation, { data, loading, error }] = useCreateCustomerMutation({
 *   variables: {
 *      json: // value for 'json'
 *   },
 * });
 */
export function useCreateCustomerMutation(baseOptions?: Apollo.MutationHookOptions<CreateCustomerMutation, CreateCustomerMutationVariables>) {
        return Apollo.useMutation<CreateCustomerMutation, CreateCustomerMutationVariables>(CreateCustomerDocument, baseOptions);
      }
export type CreateCustomerMutationHookResult = ReturnType<typeof useCreateCustomerMutation>;
export type CreateCustomerMutationResult = Apollo.MutationResult<CreateCustomerMutation>;
export type CreateCustomerMutationOptions = Apollo.BaseMutationOptions<CreateCustomerMutation, CreateCustomerMutationVariables>;
export const CreateEmployeeDocument = gql`
    mutation createEmployee($input: EMPLOYEE_INPUT!) {
  createEmployee(input: {employeeInput: $input}) {
    employee {
      id
      name
      email
      createdAt
      updatedAt
    }
  }
}
    `;
export type CreateEmployeeMutationFn = Apollo.MutationFunction<CreateEmployeeMutation, CreateEmployeeMutationVariables>;

/**
 * __useCreateEmployeeMutation__
 *
 * To run a mutation, you first call `useCreateEmployeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEmployeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEmployeeMutation, { data, loading, error }] = useCreateEmployeeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEmployeeMutation(baseOptions?: Apollo.MutationHookOptions<CreateEmployeeMutation, CreateEmployeeMutationVariables>) {
        return Apollo.useMutation<CreateEmployeeMutation, CreateEmployeeMutationVariables>(CreateEmployeeDocument, baseOptions);
      }
export type CreateEmployeeMutationHookResult = ReturnType<typeof useCreateEmployeeMutation>;
export type CreateEmployeeMutationResult = Apollo.MutationResult<CreateEmployeeMutation>;
export type CreateEmployeeMutationOptions = Apollo.BaseMutationOptions<CreateEmployeeMutation, CreateEmployeeMutationVariables>;
export const CreateInstructionDocument = gql`
    mutation createInstruction($instructionInput: INSTRUCTION_INPUT!, $shopsInput: [ID!]) {
  createInstruction(
    input: {instructionInput: $instructionInput, shopIds: $shopsInput}
  ) {
    result
  }
}
    `;
export type CreateInstructionMutationFn = Apollo.MutationFunction<CreateInstructionMutation, CreateInstructionMutationVariables>;

/**
 * __useCreateInstructionMutation__
 *
 * To run a mutation, you first call `useCreateInstructionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInstructionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInstructionMutation, { data, loading, error }] = useCreateInstructionMutation({
 *   variables: {
 *      instructionInput: // value for 'instructionInput'
 *      shopsInput: // value for 'shopsInput'
 *   },
 * });
 */
export function useCreateInstructionMutation(baseOptions?: Apollo.MutationHookOptions<CreateInstructionMutation, CreateInstructionMutationVariables>) {
        return Apollo.useMutation<CreateInstructionMutation, CreateInstructionMutationVariables>(CreateInstructionDocument, baseOptions);
      }
export type CreateInstructionMutationHookResult = ReturnType<typeof useCreateInstructionMutation>;
export type CreateInstructionMutationResult = Apollo.MutationResult<CreateInstructionMutation>;
export type CreateInstructionMutationOptions = Apollo.BaseMutationOptions<CreateInstructionMutation, CreateInstructionMutationVariables>;
export const CreateLibraryDocument = gql`
    mutation createLibrary($name: String!, $fileUrl: String!) {
  createLibrary(input: {name: $name, fileUrl: $fileUrl}) {
    library {
      id
      name
      fileUrl
    }
  }
}
    `;
export type CreateLibraryMutationFn = Apollo.MutationFunction<CreateLibraryMutation, CreateLibraryMutationVariables>;

/**
 * __useCreateLibraryMutation__
 *
 * To run a mutation, you first call `useCreateLibraryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLibraryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLibraryMutation, { data, loading, error }] = useCreateLibraryMutation({
 *   variables: {
 *      name: // value for 'name'
 *      fileUrl: // value for 'fileUrl'
 *   },
 * });
 */
export function useCreateLibraryMutation(baseOptions?: Apollo.MutationHookOptions<CreateLibraryMutation, CreateLibraryMutationVariables>) {
        return Apollo.useMutation<CreateLibraryMutation, CreateLibraryMutationVariables>(CreateLibraryDocument, baseOptions);
      }
export type CreateLibraryMutationHookResult = ReturnType<typeof useCreateLibraryMutation>;
export type CreateLibraryMutationResult = Apollo.MutationResult<CreateLibraryMutation>;
export type CreateLibraryMutationOptions = Apollo.BaseMutationOptions<CreateLibraryMutation, CreateLibraryMutationVariables>;
export const CreateProductDocument = gql`
    mutation createProduct($json: JSON!) {
  createProduct(input: {json: $json}) {
    result
    error
  }
}
    `;
export type CreateProductMutationFn = Apollo.MutationFunction<CreateProductMutation, CreateProductMutationVariables>;

/**
 * __useCreateProductMutation__
 *
 * To run a mutation, you first call `useCreateProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProductMutation, { data, loading, error }] = useCreateProductMutation({
 *   variables: {
 *      json: // value for 'json'
 *   },
 * });
 */
export function useCreateProductMutation(baseOptions?: Apollo.MutationHookOptions<CreateProductMutation, CreateProductMutationVariables>) {
        return Apollo.useMutation<CreateProductMutation, CreateProductMutationVariables>(CreateProductDocument, baseOptions);
      }
export type CreateProductMutationHookResult = ReturnType<typeof useCreateProductMutation>;
export type CreateProductMutationResult = Apollo.MutationResult<CreateProductMutation>;
export type CreateProductMutationOptions = Apollo.BaseMutationOptions<CreateProductMutation, CreateProductMutationVariables>;
export const CreateReceiptDocument = gql`
    mutation createReceipt($json: JSON!) {
  createReceipt(input: {json: $json}) {
    result
    errors
  }
}
    `;
export type CreateReceiptMutationFn = Apollo.MutationFunction<CreateReceiptMutation, CreateReceiptMutationVariables>;

/**
 * __useCreateReceiptMutation__
 *
 * To run a mutation, you first call `useCreateReceiptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReceiptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReceiptMutation, { data, loading, error }] = useCreateReceiptMutation({
 *   variables: {
 *      json: // value for 'json'
 *   },
 * });
 */
export function useCreateReceiptMutation(baseOptions?: Apollo.MutationHookOptions<CreateReceiptMutation, CreateReceiptMutationVariables>) {
        return Apollo.useMutation<CreateReceiptMutation, CreateReceiptMutationVariables>(CreateReceiptDocument, baseOptions);
      }
export type CreateReceiptMutationHookResult = ReturnType<typeof useCreateReceiptMutation>;
export type CreateReceiptMutationResult = Apollo.MutationResult<CreateReceiptMutation>;
export type CreateReceiptMutationOptions = Apollo.BaseMutationOptions<CreateReceiptMutation, CreateReceiptMutationVariables>;
export const CreateReportDocument = gql`
    mutation createReport($input: REPORT_INPUT!) {
  createReport(input: {reportInput: $input}) {
    report {
      id
    }
  }
}
    `;
export type CreateReportMutationFn = Apollo.MutationFunction<CreateReportMutation, CreateReportMutationVariables>;

/**
 * __useCreateReportMutation__
 *
 * To run a mutation, you first call `useCreateReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReportMutation, { data, loading, error }] = useCreateReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateReportMutation(baseOptions?: Apollo.MutationHookOptions<CreateReportMutation, CreateReportMutationVariables>) {
        return Apollo.useMutation<CreateReportMutation, CreateReportMutationVariables>(CreateReportDocument, baseOptions);
      }
export type CreateReportMutationHookResult = ReturnType<typeof useCreateReportMutation>;
export type CreateReportMutationResult = Apollo.MutationResult<CreateReportMutation>;
export type CreateReportMutationOptions = Apollo.BaseMutationOptions<CreateReportMutation, CreateReportMutationVariables>;
export const CreateShopDocument = gql`
    mutation createShop($input: SHOP_INPUT!, $staffPassword: String!) {
  createShop(input: {shopInput: $input, staffPassword: $staffPassword}) {
    shop {
      id
      name
      createdAt
      updatedAt
    }
  }
}
    `;
export type CreateShopMutationFn = Apollo.MutationFunction<CreateShopMutation, CreateShopMutationVariables>;

/**
 * __useCreateShopMutation__
 *
 * To run a mutation, you first call `useCreateShopMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateShopMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createShopMutation, { data, loading, error }] = useCreateShopMutation({
 *   variables: {
 *      input: // value for 'input'
 *      staffPassword: // value for 'staffPassword'
 *   },
 * });
 */
export function useCreateShopMutation(baseOptions?: Apollo.MutationHookOptions<CreateShopMutation, CreateShopMutationVariables>) {
        return Apollo.useMutation<CreateShopMutation, CreateShopMutationVariables>(CreateShopDocument, baseOptions);
      }
export type CreateShopMutationHookResult = ReturnType<typeof useCreateShopMutation>;
export type CreateShopMutationResult = Apollo.MutationResult<CreateShopMutation>;
export type CreateShopMutationOptions = Apollo.BaseMutationOptions<CreateShopMutation, CreateShopMutationVariables>;
export const DeleteLibraryDocument = gql`
    mutation deleteLibrary($id: ID!) {
  deleteLibrary(input: {id: $id}) {
    result
  }
}
    `;
export type DeleteLibraryMutationFn = Apollo.MutationFunction<DeleteLibraryMutation, DeleteLibraryMutationVariables>;

/**
 * __useDeleteLibraryMutation__
 *
 * To run a mutation, you first call `useDeleteLibraryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLibraryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLibraryMutation, { data, loading, error }] = useDeleteLibraryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteLibraryMutation(baseOptions?: Apollo.MutationHookOptions<DeleteLibraryMutation, DeleteLibraryMutationVariables>) {
        return Apollo.useMutation<DeleteLibraryMutation, DeleteLibraryMutationVariables>(DeleteLibraryDocument, baseOptions);
      }
export type DeleteLibraryMutationHookResult = ReturnType<typeof useDeleteLibraryMutation>;
export type DeleteLibraryMutationResult = Apollo.MutationResult<DeleteLibraryMutation>;
export type DeleteLibraryMutationOptions = Apollo.BaseMutationOptions<DeleteLibraryMutation, DeleteLibraryMutationVariables>;
export const LoginDocument = gql`
    mutation login($email: String!, $password: String!) {
  login(input: {email: $email, password: $password}) {
    isSuccess
    token
    user {
      id
      name
      email
      updatedAt
      createdAt
      unreadChatExist
      ... on Staff {
        shop {
          id
          name
        }
      }
    }
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, baseOptions);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const ReadRoomChatsDocument = gql`
    mutation readRoomChats($userId: ID!) {
  readRoomChats(input: {userId: $userId}) {
    unreadChatExist
  }
}
    `;
export type ReadRoomChatsMutationFn = Apollo.MutationFunction<ReadRoomChatsMutation, ReadRoomChatsMutationVariables>;

/**
 * __useReadRoomChatsMutation__
 *
 * To run a mutation, you first call `useReadRoomChatsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReadRoomChatsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [readRoomChatsMutation, { data, loading, error }] = useReadRoomChatsMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useReadRoomChatsMutation(baseOptions?: Apollo.MutationHookOptions<ReadRoomChatsMutation, ReadRoomChatsMutationVariables>) {
        return Apollo.useMutation<ReadRoomChatsMutation, ReadRoomChatsMutationVariables>(ReadRoomChatsDocument, baseOptions);
      }
export type ReadRoomChatsMutationHookResult = ReturnType<typeof useReadRoomChatsMutation>;
export type ReadRoomChatsMutationResult = Apollo.MutationResult<ReadRoomChatsMutation>;
export type ReadRoomChatsMutationOptions = Apollo.BaseMutationOptions<ReadRoomChatsMutation, ReadRoomChatsMutationVariables>;
export const UpdateCustomerDocument = gql`
    mutation updateCustomer($input: CUSTOMER_INPUT!) {
  updateCustomer(input: {customerInput: $input}) {
    customer {
      id
      lastName
      firstName
      lastKana
      firstKana
      birthDate
      gender
      address
      tel
      note
      createdAt
      updatedAt
    }
  }
}
    `;
export type UpdateCustomerMutationFn = Apollo.MutationFunction<UpdateCustomerMutation, UpdateCustomerMutationVariables>;

/**
 * __useUpdateCustomerMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerMutation, { data, loading, error }] = useUpdateCustomerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCustomerMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCustomerMutation, UpdateCustomerMutationVariables>) {
        return Apollo.useMutation<UpdateCustomerMutation, UpdateCustomerMutationVariables>(UpdateCustomerDocument, baseOptions);
      }
export type UpdateCustomerMutationHookResult = ReturnType<typeof useUpdateCustomerMutation>;
export type UpdateCustomerMutationResult = Apollo.MutationResult<UpdateCustomerMutation>;
export type UpdateCustomerMutationOptions = Apollo.BaseMutationOptions<UpdateCustomerMutation, UpdateCustomerMutationVariables>;
export const UpdateEmployeeDocument = gql`
    mutation updateEmployee($input: EMPLOYEE_INPUT!, $currentPassword: String!) {
  updateEmployee(
    input: {employeeInput: $input, currentPassword: $currentPassword}
  ) {
    employee {
      id
      name
      email
    }
  }
}
    `;
export type UpdateEmployeeMutationFn = Apollo.MutationFunction<UpdateEmployeeMutation, UpdateEmployeeMutationVariables>;

/**
 * __useUpdateEmployeeMutation__
 *
 * To run a mutation, you first call `useUpdateEmployeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmployeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmployeeMutation, { data, loading, error }] = useUpdateEmployeeMutation({
 *   variables: {
 *      input: // value for 'input'
 *      currentPassword: // value for 'currentPassword'
 *   },
 * });
 */
export function useUpdateEmployeeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEmployeeMutation, UpdateEmployeeMutationVariables>) {
        return Apollo.useMutation<UpdateEmployeeMutation, UpdateEmployeeMutationVariables>(UpdateEmployeeDocument, baseOptions);
      }
export type UpdateEmployeeMutationHookResult = ReturnType<typeof useUpdateEmployeeMutation>;
export type UpdateEmployeeMutationResult = Apollo.MutationResult<UpdateEmployeeMutation>;
export type UpdateEmployeeMutationOptions = Apollo.BaseMutationOptions<UpdateEmployeeMutation, UpdateEmployeeMutationVariables>;
export const UpdateInstructionDocument = gql`
    mutation updateInstruction($instructionInput: INSTRUCTION_INPUT!) {
  updateInstruction(input: {instructionInput: $instructionInput}) {
    result
  }
}
    `;
export type UpdateInstructionMutationFn = Apollo.MutationFunction<UpdateInstructionMutation, UpdateInstructionMutationVariables>;

/**
 * __useUpdateInstructionMutation__
 *
 * To run a mutation, you first call `useUpdateInstructionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInstructionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInstructionMutation, { data, loading, error }] = useUpdateInstructionMutation({
 *   variables: {
 *      instructionInput: // value for 'instructionInput'
 *   },
 * });
 */
export function useUpdateInstructionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInstructionMutation, UpdateInstructionMutationVariables>) {
        return Apollo.useMutation<UpdateInstructionMutation, UpdateInstructionMutationVariables>(UpdateInstructionDocument, baseOptions);
      }
export type UpdateInstructionMutationHookResult = ReturnType<typeof useUpdateInstructionMutation>;
export type UpdateInstructionMutationResult = Apollo.MutationResult<UpdateInstructionMutation>;
export type UpdateInstructionMutationOptions = Apollo.BaseMutationOptions<UpdateInstructionMutation, UpdateInstructionMutationVariables>;
export const UpdateReportStatusDocument = gql`
    mutation updateReportStatus($reportId: ID!, $status: ReportStatusEnum!, $resubmitReason: String) {
  updateReportStatus(
    input: {reportId: $reportId, status: $status, resubmitReason: $resubmitReason}
  ) {
    report {
      id
    }
  }
}
    `;
export type UpdateReportStatusMutationFn = Apollo.MutationFunction<UpdateReportStatusMutation, UpdateReportStatusMutationVariables>;

/**
 * __useUpdateReportStatusMutation__
 *
 * To run a mutation, you first call `useUpdateReportStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReportStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReportStatusMutation, { data, loading, error }] = useUpdateReportStatusMutation({
 *   variables: {
 *      reportId: // value for 'reportId'
 *      status: // value for 'status'
 *      resubmitReason: // value for 'resubmitReason'
 *   },
 * });
 */
export function useUpdateReportStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateReportStatusMutation, UpdateReportStatusMutationVariables>) {
        return Apollo.useMutation<UpdateReportStatusMutation, UpdateReportStatusMutationVariables>(UpdateReportStatusDocument, baseOptions);
      }
export type UpdateReportStatusMutationHookResult = ReturnType<typeof useUpdateReportStatusMutation>;
export type UpdateReportStatusMutationResult = Apollo.MutationResult<UpdateReportStatusMutation>;
export type UpdateReportStatusMutationOptions = Apollo.BaseMutationOptions<UpdateReportStatusMutation, UpdateReportStatusMutationVariables>;
export const UpdateShopDocument = gql`
    mutation updateShop($input: SHOP_INPUT!) {
  updateShop(input: {shopInput: $input}) {
    shop {
      id
      name
    }
  }
}
    `;
export type UpdateShopMutationFn = Apollo.MutationFunction<UpdateShopMutation, UpdateShopMutationVariables>;

/**
 * __useUpdateShopMutation__
 *
 * To run a mutation, you first call `useUpdateShopMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateShopMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateShopMutation, { data, loading, error }] = useUpdateShopMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateShopMutation(baseOptions?: Apollo.MutationHookOptions<UpdateShopMutation, UpdateShopMutationVariables>) {
        return Apollo.useMutation<UpdateShopMutation, UpdateShopMutationVariables>(UpdateShopDocument, baseOptions);
      }
export type UpdateShopMutationHookResult = ReturnType<typeof useUpdateShopMutation>;
export type UpdateShopMutationResult = Apollo.MutationResult<UpdateShopMutation>;
export type UpdateShopMutationOptions = Apollo.BaseMutationOptions<UpdateShopMutation, UpdateShopMutationVariables>;
export const UpdateStaffDocument = gql`
    mutation updateStaff($input: STAFF_INPUT!, $currentPassword: String!) {
  updateStaff(input: {staffInput: $input, currentPassword: $currentPassword}) {
    staff {
      id
      name
      email
    }
  }
}
    `;
export type UpdateStaffMutationFn = Apollo.MutationFunction<UpdateStaffMutation, UpdateStaffMutationVariables>;

/**
 * __useUpdateStaffMutation__
 *
 * To run a mutation, you first call `useUpdateStaffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStaffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStaffMutation, { data, loading, error }] = useUpdateStaffMutation({
 *   variables: {
 *      input: // value for 'input'
 *      currentPassword: // value for 'currentPassword'
 *   },
 * });
 */
export function useUpdateStaffMutation(baseOptions?: Apollo.MutationHookOptions<UpdateStaffMutation, UpdateStaffMutationVariables>) {
        return Apollo.useMutation<UpdateStaffMutation, UpdateStaffMutationVariables>(UpdateStaffDocument, baseOptions);
      }
export type UpdateStaffMutationHookResult = ReturnType<typeof useUpdateStaffMutation>;
export type UpdateStaffMutationResult = Apollo.MutationResult<UpdateStaffMutation>;
export type UpdateStaffMutationOptions = Apollo.BaseMutationOptions<UpdateStaffMutation, UpdateStaffMutationVariables>;
export const ChatUsersDocument = gql`
    query chatUsers($after: String, $first: Int) {
  chatUsers(first: $first, after: $after) {
    pageInfo {
      hasNextPage
      startCursor
      hasPreviousPage
    }
    edges {
      cursor
      node {
        id
        name
        unreadCount
      }
    }
  }
}
    `;

/**
 * __useChatUsersQuery__
 *
 * To run a query within a React component, call `useChatUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useChatUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatUsersQuery({
 *   variables: {
 *      after: // value for 'after'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useChatUsersQuery(baseOptions?: Apollo.QueryHookOptions<ChatUsersQuery, ChatUsersQueryVariables>) {
        return Apollo.useQuery<ChatUsersQuery, ChatUsersQueryVariables>(ChatUsersDocument, baseOptions);
      }
export function useChatUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChatUsersQuery, ChatUsersQueryVariables>) {
          return Apollo.useLazyQuery<ChatUsersQuery, ChatUsersQueryVariables>(ChatUsersDocument, baseOptions);
        }
export type ChatUsersQueryHookResult = ReturnType<typeof useChatUsersQuery>;
export type ChatUsersLazyQueryHookResult = ReturnType<typeof useChatUsersLazyQuery>;
export type ChatUsersQueryResult = Apollo.QueryResult<ChatUsersQuery, ChatUsersQueryVariables>;
export const CustomerDocument = gql`
    query customer($id: ID!) {
  customer(id: $id) {
    id
    lastName
    firstName
    lastKana
    firstKana
    birthDate
    gender
    address
    tel
    mobile
    visitReason
    reasonDetail
    medicineReaction
    patchTest
    note
    createdAt
    updatedAt
    lastVisitDate
    firstVisitDate
    smaregiId
  }
}
    `;

/**
 * __useCustomerQuery__
 *
 * To run a query within a React component, call `useCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCustomerQuery(baseOptions: Apollo.QueryHookOptions<CustomerQuery, CustomerQueryVariables>) {
        return Apollo.useQuery<CustomerQuery, CustomerQueryVariables>(CustomerDocument, baseOptions);
      }
export function useCustomerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomerQuery, CustomerQueryVariables>) {
          return Apollo.useLazyQuery<CustomerQuery, CustomerQueryVariables>(CustomerDocument, baseOptions);
        }
export type CustomerQueryHookResult = ReturnType<typeof useCustomerQuery>;
export type CustomerLazyQueryHookResult = ReturnType<typeof useCustomerLazyQuery>;
export type CustomerQueryResult = Apollo.QueryResult<CustomerQuery, CustomerQueryVariables>;
export const CustomersDocument = gql`
    query customers($after: String, $first: Int, $name: String) {
  customers(after: $after, first: $first, name: $name) {
    totalCount
    customers {
      id
      lastName
      firstName
      lastKana
      firstKana
      birthDate
      gender
      address
      tel
      note
      createdAt
      updatedAt
      lastVisitDate
      firstVisitDate
    }
  }
}
    `;

/**
 * __useCustomersQuery__
 *
 * To run a query within a React component, call `useCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomersQuery({
 *   variables: {
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCustomersQuery(baseOptions?: Apollo.QueryHookOptions<CustomersQuery, CustomersQueryVariables>) {
        return Apollo.useQuery<CustomersQuery, CustomersQueryVariables>(CustomersDocument, baseOptions);
      }
export function useCustomersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomersQuery, CustomersQueryVariables>) {
          return Apollo.useLazyQuery<CustomersQuery, CustomersQueryVariables>(CustomersDocument, baseOptions);
        }
export type CustomersQueryHookResult = ReturnType<typeof useCustomersQuery>;
export type CustomersLazyQueryHookResult = ReturnType<typeof useCustomersLazyQuery>;
export type CustomersQueryResult = Apollo.QueryResult<CustomersQuery, CustomersQueryVariables>;
export const EmployeesDocument = gql`
    query employees($first: Int, $after: String) {
  employees(first: $first, after: $after) {
    totalCount
    employees {
      id
      name
      email
    }
  }
}
    `;

/**
 * __useEmployeesQuery__
 *
 * To run a query within a React component, call `useEmployeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useEmployeesQuery(baseOptions?: Apollo.QueryHookOptions<EmployeesQuery, EmployeesQueryVariables>) {
        return Apollo.useQuery<EmployeesQuery, EmployeesQueryVariables>(EmployeesDocument, baseOptions);
      }
export function useEmployeesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmployeesQuery, EmployeesQueryVariables>) {
          return Apollo.useLazyQuery<EmployeesQuery, EmployeesQueryVariables>(EmployeesDocument, baseOptions);
        }
export type EmployeesQueryHookResult = ReturnType<typeof useEmployeesQuery>;
export type EmployeesLazyQueryHookResult = ReturnType<typeof useEmployeesLazyQuery>;
export type EmployeesQueryResult = Apollo.QueryResult<EmployeesQuery, EmployeesQueryVariables>;
export const InstructionDocument = gql`
    query instruction($id: ID!) {
  instruction(id: $id) {
    id
    priority
    title
    endAt
    createdAt
    content
    file
    employee {
      id
      name
    }
    instructionCategory {
      id
      name
    }
    reports {
      id
      status
      shop {
        id
        name
      }
    }
    shops {
      id
      name
    }
    reviewedReportCount
    shopCount
  }
}
    `;

/**
 * __useInstructionQuery__
 *
 * To run a query within a React component, call `useInstructionQuery` and pass it any options that fit your needs.
 * When your component renders, `useInstructionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInstructionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useInstructionQuery(baseOptions: Apollo.QueryHookOptions<InstructionQuery, InstructionQueryVariables>) {
        return Apollo.useQuery<InstructionQuery, InstructionQueryVariables>(InstructionDocument, baseOptions);
      }
export function useInstructionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InstructionQuery, InstructionQueryVariables>) {
          return Apollo.useLazyQuery<InstructionQuery, InstructionQueryVariables>(InstructionDocument, baseOptions);
        }
export type InstructionQueryHookResult = ReturnType<typeof useInstructionQuery>;
export type InstructionLazyQueryHookResult = ReturnType<typeof useInstructionLazyQuery>;
export type InstructionQueryResult = Apollo.QueryResult<InstructionQuery, InstructionQueryVariables>;
export const InstructionCategoriesDocument = gql`
    query instructionCategories {
  instructionCategories {
    id
    name
  }
}
    `;

/**
 * __useInstructionCategoriesQuery__
 *
 * To run a query within a React component, call `useInstructionCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useInstructionCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInstructionCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useInstructionCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<InstructionCategoriesQuery, InstructionCategoriesQueryVariables>) {
        return Apollo.useQuery<InstructionCategoriesQuery, InstructionCategoriesQueryVariables>(InstructionCategoriesDocument, baseOptions);
      }
export function useInstructionCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InstructionCategoriesQuery, InstructionCategoriesQueryVariables>) {
          return Apollo.useLazyQuery<InstructionCategoriesQuery, InstructionCategoriesQueryVariables>(InstructionCategoriesDocument, baseOptions);
        }
export type InstructionCategoriesQueryHookResult = ReturnType<typeof useInstructionCategoriesQuery>;
export type InstructionCategoriesLazyQueryHookResult = ReturnType<typeof useInstructionCategoriesLazyQuery>;
export type InstructionCategoriesQueryResult = Apollo.QueryResult<InstructionCategoriesQuery, InstructionCategoriesQueryVariables>;
export const InstructionsDocument = gql`
    query instructions($after: String, $first: Int) {
  instructions(after: $after, first: $first) {
    totalCount
    instructions {
      id
      priority
      title
      createdAt
      endAt
      employee {
        id
        name
      }
      instructionCategory {
        id
        name
      }
      reviewedReportCount
      shopCount
    }
  }
}
    `;

/**
 * __useInstructionsQuery__
 *
 * To run a query within a React component, call `useInstructionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInstructionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInstructionsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useInstructionsQuery(baseOptions?: Apollo.QueryHookOptions<InstructionsQuery, InstructionsQueryVariables>) {
        return Apollo.useQuery<InstructionsQuery, InstructionsQueryVariables>(InstructionsDocument, baseOptions);
      }
export function useInstructionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InstructionsQuery, InstructionsQueryVariables>) {
          return Apollo.useLazyQuery<InstructionsQuery, InstructionsQueryVariables>(InstructionsDocument, baseOptions);
        }
export type InstructionsQueryHookResult = ReturnType<typeof useInstructionsQuery>;
export type InstructionsLazyQueryHookResult = ReturnType<typeof useInstructionsLazyQuery>;
export type InstructionsQueryResult = Apollo.QueryResult<InstructionsQuery, InstructionsQueryVariables>;
export const InstructionsOnHomeDocument = gql`
    query instructionsOnHome($after: String, $first: Int) {
  instructionsOnHome(after: $after, first: $first) {
    totalCount
    instructions {
      id
      priority
      title
      createdAt
      employee {
        id
        name
      }
      instructionCategory {
        id
        name
      }
      reviewedReportCount
      shopCount
    }
  }
}
    `;

/**
 * __useInstructionsOnHomeQuery__
 *
 * To run a query within a React component, call `useInstructionsOnHomeQuery` and pass it any options that fit your needs.
 * When your component renders, `useInstructionsOnHomeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInstructionsOnHomeQuery({
 *   variables: {
 *      after: // value for 'after'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useInstructionsOnHomeQuery(baseOptions?: Apollo.QueryHookOptions<InstructionsOnHomeQuery, InstructionsOnHomeQueryVariables>) {
        return Apollo.useQuery<InstructionsOnHomeQuery, InstructionsOnHomeQueryVariables>(InstructionsOnHomeDocument, baseOptions);
      }
export function useInstructionsOnHomeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InstructionsOnHomeQuery, InstructionsOnHomeQueryVariables>) {
          return Apollo.useLazyQuery<InstructionsOnHomeQuery, InstructionsOnHomeQueryVariables>(InstructionsOnHomeDocument, baseOptions);
        }
export type InstructionsOnHomeQueryHookResult = ReturnType<typeof useInstructionsOnHomeQuery>;
export type InstructionsOnHomeLazyQueryHookResult = ReturnType<typeof useInstructionsOnHomeLazyQuery>;
export type InstructionsOnHomeQueryResult = Apollo.QueryResult<InstructionsOnHomeQuery, InstructionsOnHomeQueryVariables>;
export const LibrariesDocument = gql`
    query libraries($first: Int, $after: String) {
  libraries(first: $first, after: $after) {
    totalCount
    libraries {
      id
      name
      fileUrl
      createdAt
    }
  }
}
    `;

/**
 * __useLibrariesQuery__
 *
 * To run a query within a React component, call `useLibrariesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLibrariesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLibrariesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useLibrariesQuery(baseOptions?: Apollo.QueryHookOptions<LibrariesQuery, LibrariesQueryVariables>) {
        return Apollo.useQuery<LibrariesQuery, LibrariesQueryVariables>(LibrariesDocument, baseOptions);
      }
export function useLibrariesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LibrariesQuery, LibrariesQueryVariables>) {
          return Apollo.useLazyQuery<LibrariesQuery, LibrariesQueryVariables>(LibrariesDocument, baseOptions);
        }
export type LibrariesQueryHookResult = ReturnType<typeof useLibrariesQuery>;
export type LibrariesLazyQueryHookResult = ReturnType<typeof useLibrariesLazyQuery>;
export type LibrariesQueryResult = Apollo.QueryResult<LibrariesQuery, LibrariesQueryVariables>;
export const PresignDownloadDocument = gql`
    query presignDownload($fileUrl: String!) {
  presignDownload(fileUrl: $fileUrl) {
    fileUrl
  }
}
    `;

/**
 * __usePresignDownloadQuery__
 *
 * To run a query within a React component, call `usePresignDownloadQuery` and pass it any options that fit your needs.
 * When your component renders, `usePresignDownloadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePresignDownloadQuery({
 *   variables: {
 *      fileUrl: // value for 'fileUrl'
 *   },
 * });
 */
export function usePresignDownloadQuery(baseOptions: Apollo.QueryHookOptions<PresignDownloadQuery, PresignDownloadQueryVariables>) {
        return Apollo.useQuery<PresignDownloadQuery, PresignDownloadQueryVariables>(PresignDownloadDocument, baseOptions);
      }
export function usePresignDownloadLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PresignDownloadQuery, PresignDownloadQueryVariables>) {
          return Apollo.useLazyQuery<PresignDownloadQuery, PresignDownloadQueryVariables>(PresignDownloadDocument, baseOptions);
        }
export type PresignDownloadQueryHookResult = ReturnType<typeof usePresignDownloadQuery>;
export type PresignDownloadLazyQueryHookResult = ReturnType<typeof usePresignDownloadLazyQuery>;
export type PresignDownloadQueryResult = Apollo.QueryResult<PresignDownloadQuery, PresignDownloadQueryVariables>;
export const PresignUploadDocument = gql`
    query presignUpload($fileName: String!, $klass: String!) {
  presignUpload(fileName: $fileName, klass: $klass) {
    url
    fields
  }
}
    `;

/**
 * __usePresignUploadQuery__
 *
 * To run a query within a React component, call `usePresignUploadQuery` and pass it any options that fit your needs.
 * When your component renders, `usePresignUploadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePresignUploadQuery({
 *   variables: {
 *      fileName: // value for 'fileName'
 *      klass: // value for 'klass'
 *   },
 * });
 */
export function usePresignUploadQuery(baseOptions: Apollo.QueryHookOptions<PresignUploadQuery, PresignUploadQueryVariables>) {
        return Apollo.useQuery<PresignUploadQuery, PresignUploadQueryVariables>(PresignUploadDocument, baseOptions);
      }
export function usePresignUploadLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PresignUploadQuery, PresignUploadQueryVariables>) {
          return Apollo.useLazyQuery<PresignUploadQuery, PresignUploadQueryVariables>(PresignUploadDocument, baseOptions);
        }
export type PresignUploadQueryHookResult = ReturnType<typeof usePresignUploadQuery>;
export type PresignUploadLazyQueryHookResult = ReturnType<typeof usePresignUploadLazyQuery>;
export type PresignUploadQueryResult = Apollo.QueryResult<PresignUploadQuery, PresignUploadQueryVariables>;
export const ReportDocument = gql`
    query report($id: ID!) {
  report(id: $id) {
    id
    content
    createdAt
    updatedAt
    attachedFile
    status
    shop {
      id
      name
    }
    instruction {
      id
      title
      priority
      instructionCategory {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useReportQuery__
 *
 * To run a query within a React component, call `useReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReportQuery(baseOptions: Apollo.QueryHookOptions<ReportQuery, ReportQueryVariables>) {
        return Apollo.useQuery<ReportQuery, ReportQueryVariables>(ReportDocument, baseOptions);
      }
export function useReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReportQuery, ReportQueryVariables>) {
          return Apollo.useLazyQuery<ReportQuery, ReportQueryVariables>(ReportDocument, baseOptions);
        }
export type ReportQueryHookResult = ReturnType<typeof useReportQuery>;
export type ReportLazyQueryHookResult = ReturnType<typeof useReportLazyQuery>;
export type ReportQueryResult = Apollo.QueryResult<ReportQuery, ReportQueryVariables>;
export const ReportsDocument = gql`
    query reports($after: String, $first: Int) {
  reports(after: $after, first: $first) {
    totalCount
    reports {
      id
      content
      status
      createdAt
      updatedAt
      attachedFile
      resubmitReason
      shop {
        id
        name
      }
      instruction {
        id
        title
        priority
        endAt
        instructionCategory {
          id
          name
        }
      }
    }
  }
}
    `;

/**
 * __useReportsQuery__
 *
 * To run a query within a React component, call `useReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useReportsQuery(baseOptions?: Apollo.QueryHookOptions<ReportsQuery, ReportsQueryVariables>) {
        return Apollo.useQuery<ReportsQuery, ReportsQueryVariables>(ReportsDocument, baseOptions);
      }
export function useReportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReportsQuery, ReportsQueryVariables>) {
          return Apollo.useLazyQuery<ReportsQuery, ReportsQueryVariables>(ReportsDocument, baseOptions);
        }
export type ReportsQueryHookResult = ReturnType<typeof useReportsQuery>;
export type ReportsLazyQueryHookResult = ReturnType<typeof useReportsLazyQuery>;
export type ReportsQueryResult = Apollo.QueryResult<ReportsQuery, ReportsQueryVariables>;
export const ReportsOnHomeDocument = gql`
    query reportsOnHome($after: String, $first: Int) {
  reportsOnHome(after: $after, first: $first) {
    totalCount
    reports {
      id
      content
      status
      createdAt
      updatedAt
      attachedFile
      shop {
        id
        name
      }
      resubmitReason
      instruction {
        id
        title
        priority
        endAt
        instructionCategory {
          id
          name
        }
      }
    }
  }
}
    `;

/**
 * __useReportsOnHomeQuery__
 *
 * To run a query within a React component, call `useReportsOnHomeQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportsOnHomeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportsOnHomeQuery({
 *   variables: {
 *      after: // value for 'after'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useReportsOnHomeQuery(baseOptions?: Apollo.QueryHookOptions<ReportsOnHomeQuery, ReportsOnHomeQueryVariables>) {
        return Apollo.useQuery<ReportsOnHomeQuery, ReportsOnHomeQueryVariables>(ReportsOnHomeDocument, baseOptions);
      }
export function useReportsOnHomeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReportsOnHomeQuery, ReportsOnHomeQueryVariables>) {
          return Apollo.useLazyQuery<ReportsOnHomeQuery, ReportsOnHomeQueryVariables>(ReportsOnHomeDocument, baseOptions);
        }
export type ReportsOnHomeQueryHookResult = ReturnType<typeof useReportsOnHomeQuery>;
export type ReportsOnHomeLazyQueryHookResult = ReturnType<typeof useReportsOnHomeLazyQuery>;
export type ReportsOnHomeQueryResult = Apollo.QueryResult<ReportsOnHomeQuery, ReportsOnHomeQueryVariables>;
export const RoomChatsDocument = gql`
    query roomChats($userId: ID!, $after: String, $first: Int) {
  roomChats(userId: $userId, after: $after, first: $first) {
    pageInfo {
      hasNextPage
      startCursor
      hasPreviousPage
      endCursor
    }
    edges {
      cursor
      node {
        id
        roomId
        content
        senderId
        fileUrl
        fileName
        createdAt
      }
    }
  }
}
    `;

/**
 * __useRoomChatsQuery__
 *
 * To run a query within a React component, call `useRoomChatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoomChatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoomChatsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useRoomChatsQuery(baseOptions: Apollo.QueryHookOptions<RoomChatsQuery, RoomChatsQueryVariables>) {
        return Apollo.useQuery<RoomChatsQuery, RoomChatsQueryVariables>(RoomChatsDocument, baseOptions);
      }
export function useRoomChatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RoomChatsQuery, RoomChatsQueryVariables>) {
          return Apollo.useLazyQuery<RoomChatsQuery, RoomChatsQueryVariables>(RoomChatsDocument, baseOptions);
        }
export type RoomChatsQueryHookResult = ReturnType<typeof useRoomChatsQuery>;
export type RoomChatsLazyQueryHookResult = ReturnType<typeof useRoomChatsLazyQuery>;
export type RoomChatsQueryResult = Apollo.QueryResult<RoomChatsQuery, RoomChatsQueryVariables>;
export const RoomsDocument = gql`
    query rooms($after: String, $first: Int) {
  rooms(after: $after, first: $first) {
    totalCount
    rooms {
      id
      createdAt
      updatedAt
      shop {
        id
        name
      }
      employee {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useRoomsQuery__
 *
 * To run a query within a React component, call `useRoomsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoomsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoomsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useRoomsQuery(baseOptions?: Apollo.QueryHookOptions<RoomsQuery, RoomsQueryVariables>) {
        return Apollo.useQuery<RoomsQuery, RoomsQueryVariables>(RoomsDocument, baseOptions);
      }
export function useRoomsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RoomsQuery, RoomsQueryVariables>) {
          return Apollo.useLazyQuery<RoomsQuery, RoomsQueryVariables>(RoomsDocument, baseOptions);
        }
export type RoomsQueryHookResult = ReturnType<typeof useRoomsQuery>;
export type RoomsLazyQueryHookResult = ReturnType<typeof useRoomsLazyQuery>;
export type RoomsQueryResult = Apollo.QueryResult<RoomsQuery, RoomsQueryVariables>;
export const SalesAnalysesDocument = gql`
    query salesAnalyses($startAt: ISO8601DateTime!, $endAt: ISO8601DateTime!) {
  salesAnalyses(startAt: $startAt, endAt: $endAt) {
    shop {
      id
      name
    }
    salesTotal
    costTotal
    costTotalRatio
    grossMargin
    grossMarginRatio
    salesTargetDaily
    salesTargetDailyRatio
  }
}
    `;

/**
 * __useSalesAnalysesQuery__
 *
 * To run a query within a React component, call `useSalesAnalysesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesAnalysesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalesAnalysesQuery({
 *   variables: {
 *      startAt: // value for 'startAt'
 *      endAt: // value for 'endAt'
 *   },
 * });
 */
export function useSalesAnalysesQuery(baseOptions: Apollo.QueryHookOptions<SalesAnalysesQuery, SalesAnalysesQueryVariables>) {
        return Apollo.useQuery<SalesAnalysesQuery, SalesAnalysesQueryVariables>(SalesAnalysesDocument, baseOptions);
      }
export function useSalesAnalysesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SalesAnalysesQuery, SalesAnalysesQueryVariables>) {
          return Apollo.useLazyQuery<SalesAnalysesQuery, SalesAnalysesQueryVariables>(SalesAnalysesDocument, baseOptions);
        }
export type SalesAnalysesQueryHookResult = ReturnType<typeof useSalesAnalysesQuery>;
export type SalesAnalysesLazyQueryHookResult = ReturnType<typeof useSalesAnalysesLazyQuery>;
export type SalesAnalysesQueryResult = Apollo.QueryResult<SalesAnalysesQuery, SalesAnalysesQueryVariables>;
export const ShopDocument = gql`
    query shop($id: ID!) {
  shop(id: $id) {
    id
    name
    status
    smaregiId
    staffs {
      id
      name
      email
    }
    reports {
      id
      content
      status
      instruction {
        id
        title
        content
        instructionCategory {
          id
          name
        }
      }
    }
    customers {
      id
      lastName
      firstName
    }
  }
}
    `;

/**
 * __useShopQuery__
 *
 * To run a query within a React component, call `useShopQuery` and pass it any options that fit your needs.
 * When your component renders, `useShopQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShopQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useShopQuery(baseOptions: Apollo.QueryHookOptions<ShopQuery, ShopQueryVariables>) {
        return Apollo.useQuery<ShopQuery, ShopQueryVariables>(ShopDocument, baseOptions);
      }
export function useShopLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShopQuery, ShopQueryVariables>) {
          return Apollo.useLazyQuery<ShopQuery, ShopQueryVariables>(ShopDocument, baseOptions);
        }
export type ShopQueryHookResult = ReturnType<typeof useShopQuery>;
export type ShopLazyQueryHookResult = ReturnType<typeof useShopLazyQuery>;
export type ShopQueryResult = Apollo.QueryResult<ShopQuery, ShopQueryVariables>;
export const ShopsDocument = gql`
    query shops($ids: [ID!], $after: String, $first: Int) {
  shops(ids: $ids, after: $after, first: $first) {
    totalCount
    shops {
      id
      name
    }
  }
}
    `;

/**
 * __useShopsQuery__
 *
 * To run a query within a React component, call `useShopsQuery` and pass it any options that fit your needs.
 * When your component renders, `useShopsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShopsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useShopsQuery(baseOptions?: Apollo.QueryHookOptions<ShopsQuery, ShopsQueryVariables>) {
        return Apollo.useQuery<ShopsQuery, ShopsQueryVariables>(ShopsDocument, baseOptions);
      }
export function useShopsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShopsQuery, ShopsQueryVariables>) {
          return Apollo.useLazyQuery<ShopsQuery, ShopsQueryVariables>(ShopsDocument, baseOptions);
        }
export type ShopsQueryHookResult = ReturnType<typeof useShopsQuery>;
export type ShopsLazyQueryHookResult = ReturnType<typeof useShopsLazyQuery>;
export type ShopsQueryResult = Apollo.QueryResult<ShopsQuery, ShopsQueryVariables>;
export const TransactionDocument = gql`
    query transaction($transactionHeadId: ID!) {
  transaction(transactionHeadId: $transactionHeadId) {
    productName
    price
    quantity
    transactionDateTime
    transactionDetailId
  }
}
    `;

/**
 * __useTransactionQuery__
 *
 * To run a query within a React component, call `useTransactionQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransactionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransactionQuery({
 *   variables: {
 *      transactionHeadId: // value for 'transactionHeadId'
 *   },
 * });
 */
export function useTransactionQuery(baseOptions: Apollo.QueryHookOptions<TransactionQuery, TransactionQueryVariables>) {
        return Apollo.useQuery<TransactionQuery, TransactionQueryVariables>(TransactionDocument, baseOptions);
      }
export function useTransactionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TransactionQuery, TransactionQueryVariables>) {
          return Apollo.useLazyQuery<TransactionQuery, TransactionQueryVariables>(TransactionDocument, baseOptions);
        }
export type TransactionQueryHookResult = ReturnType<typeof useTransactionQuery>;
export type TransactionLazyQueryHookResult = ReturnType<typeof useTransactionLazyQuery>;
export type TransactionQueryResult = Apollo.QueryResult<TransactionQuery, TransactionQueryVariables>;
export const TransactionsDocument = gql`
    query transactions($customerSmaregiId: ID!, $startDate: ISO8601DateTime!) {
  transactions(customerSmaregiId: $customerSmaregiId, startDate: $startDate) {
    transactionHeadId
    transactionDateTime
    amount
    returnAmount
    total
  }
}
    `;

/**
 * __useTransactionsQuery__
 *
 * To run a query within a React component, call `useTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransactionsQuery({
 *   variables: {
 *      customerSmaregiId: // value for 'customerSmaregiId'
 *      startDate: // value for 'startDate'
 *   },
 * });
 */
export function useTransactionsQuery(baseOptions: Apollo.QueryHookOptions<TransactionsQuery, TransactionsQueryVariables>) {
        return Apollo.useQuery<TransactionsQuery, TransactionsQueryVariables>(TransactionsDocument, baseOptions);
      }
export function useTransactionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TransactionsQuery, TransactionsQueryVariables>) {
          return Apollo.useLazyQuery<TransactionsQuery, TransactionsQueryVariables>(TransactionsDocument, baseOptions);
        }
export type TransactionsQueryHookResult = ReturnType<typeof useTransactionsQuery>;
export type TransactionsLazyQueryHookResult = ReturnType<typeof useTransactionsLazyQuery>;
export type TransactionsQueryResult = Apollo.QueryResult<TransactionsQuery, TransactionsQueryVariables>;
export const UserDocument = gql`
    query user {
  user {
    id
    name
    email
    createdAt
    updatedAt
    unreadChatExist
    ... on Staff {
      shop {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserQuery(baseOptions?: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>) {
        return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, baseOptions);
      }
export function useUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
          return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, baseOptions);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;