import React from 'react';
import {Provider} from 'react-redux';
import {ConnectedRouter} from 'connected-react-router';
import KireiLayout from '@kirei/core/KireiLayout';
import AuthRoutes from '@kirei/utility/AuthRoutes';
import LocaleProvider from '@kirei/utility/LocaleProvider';
import CremaThemeProvider from '@kirei/utility/CremaThemeProvider';
import CremaStyleProvider from '@kirei/utility/CremaStyleProvider';
import ContextProvider from '@kirei/utility/ContextProvider';

import configureStore, {history} from './redux/store';
import CssBaseline from '@material-ui/core/CssBaseline';

const store = configureStore();

const App = () => {
  return (
    <ContextProvider>
      <Provider store={store}>
        <CremaThemeProvider>
          <CremaStyleProvider>
            <LocaleProvider>
              <ConnectedRouter history={history}>
                <AuthRoutes>
                  <CssBaseline />
                  <KireiLayout />
                </AuthRoutes>
              </ConnectedRouter>
            </LocaleProvider>
          </CremaStyleProvider>
        </CremaThemeProvider>
      </Provider>
    </ContextProvider>
  );
};

export default App;
