import React from 'react';

export const smaregiImportConfig = [
  {
    auth: ['Employee'],
    routes: [
      {
        path: '/smaregiImport/index',
        component: React.lazy(() => import('./ImportIndex')),
      },
    ],
  },
];
