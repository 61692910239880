import {useDispatch, useSelector} from 'react-redux';
import {useEffect, useState} from 'react';
import {
  fetchStart,
  fetchSuccess,
  setJWTToken,
  fetchError,
  getUserObject,
} from '../../redux/actions';
import {AppState} from '../../redux/store';
import {AuthUser} from '../../types/models/AuthUser';
import {UPDATE_AUTH_USER} from 'types/actions/Auth.actions';
import {useChatUsersQuery, useUserQuery} from 'graphql/types';
import * as Apollo from '@apollo/client';
import {getChatList} from 'redux/actions/ChatApp';

export const useAuthToken = (): [boolean, AuthUser | null] => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const {user} = useSelector<AppState, AppState['auth']>(({auth}) => auth);
  const token = localStorage.getItem('token');
  const {data, error: queryError, loading: queryLoading} = useUserQuery({
    skip: !token,
  });

  useEffect(() => {
    const validateAuth = async () => {
      dispatch(fetchStart());
      if (!token) {
        dispatch(fetchSuccess());
        return;
      }

      if (queryError) {
        dispatch(fetchSuccess());
        return;
      }

      dispatch(setJWTToken(token));
      dispatch(fetchSuccess());
      if (data) {
        const user = getUserObject(data.user);
        dispatch({
          type: UPDATE_AUTH_USER,
          payload: user,
        });
      }

      // TODO: cookieに変更した時ようにコメントアウト
      // try {
      //   const res = await jwtAxios.get('/auth');
      //   dispatch(fetchSuccess());
      //   dispatch({
      //     type: UPDATE_AUTH_USER,
      //     payload: {
      //       authType: AuthType.JWT_AUTH,
      //       displayName: res.data.name,
      //       email: res.data.email,
      //       role: defaultUser.role,
      //       token: res.data._id,
      //       photoURL: res.data.avatar,
      //     },
      //   });
      //   return;
      // } catch (err) {
      //   dispatch(fetchSuccess());
      //   return;
      // }
    };

    const checkAuth = () => {
      Promise.all([validateAuth()]).then(() => {
        setLoading(queryLoading);
      });
    };
    checkAuth();
  }, [dispatch, data, queryLoading, queryError, token]);

  return [loading, user];
};

export const useAuthUser = (): AuthUser | null => {
  const {user} = useSelector<AppState, AppState['auth']>(({auth}) => auth);
  if (user) {
    return user;
  }
  return null;
};

export const useInfoView = (
  args: Pick<Apollo.QueryResult, 'data' | 'loading' | 'error'>,
) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (args.loading) {
      dispatch(fetchStart());
    }
    if (args.error) {
      dispatch(fetchError('エラーが発生しました'));
    }
    if (args.data) {
      dispatch(fetchSuccess());
    }
  });
};

export const useFetchChatUsers = () => {
  const dispatch = useDispatch();
  const {data, loading, error} = useChatUsersQuery({
    // variables: {
    //   // TODO: ページネーション をつける？
    // },
    onCompleted: (res) => {
      dispatch(getChatList(res.chatUsers.edges));
    },
    fetchPolicy: 'no-cache',
  });
  console.log(error);
  useInfoView({data, loading, error});
};
