import Settings from './Setting';
import CommonReducer from './CommonReducer';
import Auth from './Auth';
import ChatApp from './ChatApp';

const reducers = {
  settings: Settings,
  auth: Auth,
  chatApp: ChatApp,
  common: CommonReducer,
};

export default reducers;
