import React from 'react';

export const employeeConfig = [
  {
    auth: ['Employee'],
    routes: [
      {
        path: '/settings/employees/index',
        component: React.lazy(() => import('./EmployeeLists')),
      },
      {
        path: '/settings/employees/new',
        component: React.lazy(() => import('./CreateEmployee')),
      },
    ],
  },
];
