import React from 'react';

export const libraryConfig = [
  {
    auth: ['Employee', 'Staff'],
    routes: [
      {
        path: '/library/index',
        component: React.lazy(() => import('./Libraries')),
      },
    ],
  },
  {
    auth: ['Employee'],
    routes: [
      {
        path: '/library/new',
        component: React.lazy(() => import('./CreateLibrary')),
      },
    ],
  },
];
