import {NavStyle} from '../../../shared/constants/AppEnums';
import HeaderUserLayout from './UserHeader';

interface LayoutsProps {
  [x: string]: any;
}

const Layouts: LayoutsProps = {
  [NavStyle.HEADER_USER]: HeaderUserLayout,
};
export default Layouts;
