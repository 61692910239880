import {ChatUser, ChatMessage, ChatMessages} from '../models/apps/Chat';

export const ADD_NEW_MESSAGE = 'ADD_NEW_MESSAGE';
export const DELETE_MESSAGE = 'DELETE_MESSAGE';
export const DELETE_USER_MESSAGES = 'DELETE_USER_MESSAGES';
export const EDIT_MESSAGE = 'EDIT_MESSAGE';
export const GET_CHAT_LIST = 'GET_CHAT_LIST';
export const GET_USER_MESSAGES = 'GET_USER_MESSAGES';
export const SELECT_USER = 'SELECT_USER';
export const TOGGLE_CHAT_DRAWER = 'TOGGLE_CHAT_DRAWER';

export interface AddNewMessageActions {
  type: typeof ADD_NEW_MESSAGE;
  payload: {
    userMessage: ChatMessage;
  };
}

export interface DeleteMessageActions {
  type: typeof DELETE_MESSAGE;
  payload: {
    user: ChatUser;
    userMessages: ChatActions;
  };
}

export interface DeleteUserMessageActions {
  type: typeof DELETE_USER_MESSAGES;
  payload: ChatUser;
}

export interface EditMessageActions {
  type: typeof EDIT_MESSAGE;
  payload: {
    data: {
      user: ChatUser;
      userMessages: ChatMessages;
    };
  };
}
export interface GetChatListActions {
  type: typeof GET_CHAT_LIST;
  payload: ChatUser[];
}

export interface GetUserMessageActions {
  type: typeof GET_USER_MESSAGES;
  payload: ChatMessages | undefined;
}

export interface SelectUserActions {
  type: typeof SELECT_USER;
  payload: ChatUser | null;
}

export interface ToggleChatDrawerActions {
  type: typeof TOGGLE_CHAT_DRAWER;
}

export type ChatActions =
  | AddNewMessageActions
  | DeleteMessageActions
  | DeleteUserMessageActions
  | EditMessageActions
  | GetChatListActions
  | GetUserMessageActions
  | SelectUserActions
  | ToggleChatDrawerActions;
