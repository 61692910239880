import React from 'react';

export const analysisConfig = [
  {
    auth: ['Employee'],
    routes: [
      {
        path: '/analysis/index',
        component: React.lazy(() => import('./AnalysisIndex')),
      },
    ],
  },
];
