import React, {useContext} from 'react';
import AppHeader from './AppHeader';
import AppSidebar from './AppSidebar';
import {ContentView} from '../../../index';
import Hidden from '@material-ui/core/Hidden';
import Box from '@material-ui/core/Box';
import useStyles from './index.style';
import clsx from 'clsx';
import AppContext from '../../../utility/AppContext';
import AppFixedFooter from './AppFixedFooter';
import {LayoutType} from '../../../../shared/constants/AppEnums';
import AppContextPropsType from '../../../../types/AppContextPropsType';
import LinkTabs from '../../../../shared/components/LinkTabs';
import {useLocation} from 'react-router-dom';

interface HeaderUserLayoutProps {}

const links = (path: string) => {
  switch (path) {
    case 'shopManagement':
      return [
        {
          link: '/shopManagement/instructions/index',
          label: '業務指示',
          auth: ['Employee', 'Staff'],
        },
        {
          link: '/shopManagement/report/index',
          label: '業務報告',
          auth: ['Employee', 'Staff'],
        },
        {
          link: '/shopManagement/shop/index',
          label: '店舗一覧',
          auth: ['Employee'],
        },
      ];
    case 'customers':
      return [
        {
          link: '/customers/index',
          label: '顧客情報',
          auth: ['Employee', 'Staff'],
        },
      ];
    case 'mypage':
      return [
        {
          link: '/mypage/edit',
          label: '編集',
          auth: ['Employee', 'Staff'],
        },
      ];
    case 'room':
      return [
        {
          link: '/room/index',
          label: 'メッセージ',
          auth: ['Employee', 'Staff'],
        },
      ];
    case 'settings':
      return [
        {
          link: '/settings/employees/index',
          label: '従業員管理',
          auth: ['Employee'],
        },
      ];
    case 'library':
      return [
        {
          link: '/library/index',
          label: 'ファイル一覧',
          auth: ['Employee', 'Staff'],
        },
      ];
    default:
      return [];
  }
};

const HeaderUserLayout: React.FC<HeaderUserLayoutProps> = () => {
  const {footer, themeStyle, layoutType, footerType} = useContext<
    AppContextPropsType
  >(AppContext);
  const location = useLocation();
  const path = location.pathname;
  const pattern = /^\/.*?\//;
  const classes = useStyles({themeStyle, footer});

  const matches = path.match(pattern);
  const pathName = matches && matches[0].slice(1, -1);

  return (
    <Box
      className={clsx(
        classes.appMain,
        layoutType === LayoutType.BOXED ? classes.boxedLayout : '',
        {
          appMainFooter: footer && footerType === 'fluid',
          appMainFixedFooter: footer && footerType === 'fixed',
        },
      )}>
      <AppHeader />

      <Box className={classes.mainContent}>
        <AppSidebar />
        <Hidden mdDown>
          <Box className={classes.mainContainer}>
            <LinkTabs links={pathName ? links(pathName) : []} />

            <ContentView />
            <AppFixedFooter />
          </Box>
        </Hidden>

        <Hidden lgUp>
          <Box className={classes.mainContainerFull}>
            <LinkTabs links={pathName ? links(pathName) : []} />

            <ContentView />
            <AppFixedFooter />
          </Box>
        </Hidden>
      </Box>
    </Box>
  );
};

export default HeaderUserLayout;
